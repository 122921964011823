const SET_LANG = "lang/SET_LANG"

const initialState = {
  lang:"RO",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}

export const setLang = data => ({
  type: SET_LANG,
  payload: data,
})
