import { useState } from "react"
import { LoadingScreenContext } from "./context"

export const LoadingScreenProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <LoadingScreenContext.Provider value={{isLoading, setIsLoading}}>
            {children}
        </LoadingScreenContext.Provider>
    )
}