import React, { Suspense, Fragment, lazy, useContext } from "react"
import { Switch, Redirect, Route } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import AuthLayout from "layouts/auth";
import AdminDashboard from "layouts/dashboards/AdminDashboard";
import CitizenDashboard from "layouts/dashboards/CitizenDashboard";
import MainLayout from "../layouts/main/MainLayout"
import { LoadingScreenProvider, LoadingScreenContext } from "contexts/loadingScreen"

export const renderRoutes = (routes = []) => (
	<LoadingScreenProvider>
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? renderRoutes(route.routes) : <ScreenComponent {...{
                                            Component,
                                            props,
                                            route
                                        }} />}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    </LoadingScreenProvider>
);

const ScreenComponent = ({ Component, props }) => {
    const { isLoading } = useContext(LoadingScreenContext)

    return (
        <>
            <Component {...props} />
            {isLoading && <LoadingScreen />}
        </>
    )
}

const routes = [
	{
		path: "/auth",
		layout: AuthLayout,
		routes: [
			{
				exact: true,
				path: "/auth/signup",
				component: lazy(() => import("views/signUp/signUp.view"))
			},
			{
				exact: true,
				path: "/auth/signup/success",
				component: lazy(() => import("views/signUp/requestConfirmation"))
			},
			{
				exact: true,
				path: "/auth/signin",
				component: lazy(() => import("views/auth/authCard")),
			},
			{
				exact: true,
				path: "/auth/admin",
				component: lazy(() => import("views/auth/authCard")),
			},
			{
				exact: true,
				path: "/auth/resetPassword",
				component: lazy(() => import("views/resetPassword/resetPassword.view")),
			},
			{
				exact: true,
				path: '/auth/:action/:token/:email',
				component: lazy(() => import('views/auth/passwordCard/passwordCard'))
			},
			{
				path: '*',
				component: () => <Redirect to='/auth/signin' />
			}
		]
	},

	{
		path: '/dashboard',
		layout: MainLayout,
		routes: [
			{
				exact: true,
				path: '/dashboard',
				component: lazy(() => import("views/citizenDashboard"))
			},
			{
				exact: true,
				path: '/dashboard/news/:id',
				component: lazy(() => import("views/articleCitizen"))
			},
			{
				exact: true,
				path: '/dashboard/online-forms',
				component: lazy(() => import("views/onlineForms/"))
			},
			{
				exact: true,
				path: '/dashboard/online-forms/form',
				component: lazy(() => import("views/onlineForms/form"))
			},
			{
				exact: true,
				path: '/dashboard/online-forms/request-confirmation',
				component: lazy(() => import("views/onlineForms/requestConfirmation"))
			},
			{
				exact: true,
				path: '/dashboard/online-forms/rating',
				component: lazy(() => import("views/onlineForms/rating"))
			},
			{
				exact: true,
				path: "/dashboard/online-applications",
				component: lazy(() => import("views/onlineApplications")),
			},
			{
				exact: true,
				path: "/dashboard/online-applications/form",
				component: lazy(() => import("views/onlineApplications/form")),
			},
			{
				exact: true,
				path: '/dashboard/profile',
				component: lazy(() => import("views/citizen"))
			},
			{
				exact: true,
				path: "/dashboard/form-list/form",
				component: lazy(() => import("views/dev/victor/formList/forms/form")),
			},
		],
	},

	{
		path: "/web-admin",
		layout: MainLayout,
		routes: [

			{
				exact: true,
				path: "/web-admin",
				component: lazy(() => import("views/dashboard")),
			},
			{
				exact: true,
				path: "/web-admin/form",
				component: lazy(() => import("views/citizensOnlineApplications/form")),
			},
			{
				exact: true,
				path: '/web-admin/news-list',
				component: lazy(() => import("views/newsList"))
			},
			{
				exact: true,
				path: '/web-admin/news-list/form',
				component: lazy(() => import("views/newsList/form"))
			},
			{
				exact: true,
				path: "/web-admin/citizens-online-applications",
				component: lazy(() => import("views/citizensOnlineApplications")),
			},
			{
				exact: true,
				path: "/web-admin/citizens-online-applications/form",
				component: lazy(() => import("views/citizensOnlineApplications/form")),
			},
			{
				exact: true,
				path: "/web-admin/users/role-list",
				component: lazy(() => import("views/roleList")),
			},
			{
				exact: true,
				path: "/web-admin/users/role-list/form",
				component: lazy(() => import("views/roleList/roles/roleForm")),
			},
			{
				exact: true,
				path: "/web-admin/users/role-list/add",
				component: lazy(() => import("views/roleList/roles/roleForm")),
			},
			{
				exact: true,
				path: "/web-admin/citizen/citizen-list/form",
				component: lazy(() => import("views/citizenList/form")),
			},
			{
				exact: true,
				path: "/web-admin/citizen/citizen-list/form/add-representation",
				component: lazy(() => import("views/citizenList/representationForm")),
			},
			{
				exact: true,
				path: "/web-admin/profile",
				component: lazy(() => import("views/admin/user"))
			},
			{
				exact: false,
				path: "/web-admin/articleeditor",
				component: lazy(() => import("views/dev/articleEditor.jsx")),
			},
			{
				exact: false,
				path: "/web-admin/victor/formBuilder",
				component: lazy(() => import("views/dev/victor/formBuilder.jsx")),
			},
			{
				exact: true,
				path: "/web-admin/form-list",
				component: lazy(() => import("views/dev/victor/formList/index")),
			},
			{
				exact: true,
				path: "/web-admin/form-list/form",
				component: lazy(() => import("views/dev/victor/formList/forms/form")),
			},
			{
				exact: true,
				path: "/web-admin/form-list/create-form",
				component: lazy(() => import("views/dev/victor/wizzardForm/index")),
			},
			{
				exact: true,
				path: "/web-admin/form-list/create-form/form-wizzard",
				component: lazy(() => import("views/dev/victor/wizzardForm/wizzardForm")),
			},
			{
				exact: true,
				path: "/web-admin/users/admin-user-list",
				component: lazy(() => import("views/adminUserList")),
			},
			{
				exact: true,
				path: "/web-admin/citizen/citizen-list",
				component: lazy(() => import("views/citizenList")),
			},
			{
				exact: true,
				path: "/web-admin/citizen/citizen-list/form",
				component: lazy(() => import("views/citizenList/form")),
			},
			{
				exact: true,
				path: "/web-admin/citizen/citizen-list/representatives",
				component: lazy(() => import("views/citizenList/RepresentativeListForm")),
			},
			{
				exact: true,
				path: "/web-admin/user/user-list",
				component: lazy(() => import("views/userList")),
			},
			{
				exact: true,
				path: "/web-admin/user/user-list/form",
				component: lazy(() => import("views/userList/form")),
			},
			{
				exact: true,
				path: "/web-admin/rating-list",
				component: lazy(() => import("views/ratingList")),
			},
			{
				exact: true,
				path: "/web-admin/rating-list/form",
				component: lazy(() => import("views/ratingList/form")),
			},
			{
				exact: true,
				path: "/web-admin/enrolment-request-list",
				component: lazy(() => import("views/enrollmentRequestList")),
			},
			{
				exact: true,
				path: "/web-admin/enrolment-request-list/form",
				component: lazy(() => import("views/enrollmentRequestList/forms/form")),
			},
			{
				exact: true,
				path: "/web-admin/representatives-list",
				component: lazy(() => import("views/representativesList")),
			},
			{
				exact: true,
				path: "/web-admin/representatives-list/form",
				component: lazy(() => import("views/representativesList/form")),
			},
		],
		//guard: AuthGuard,
	},

	{
		exact: true,
		path: "/dev/gyula/toastr",
		component: lazy(() => import("views/dev/gyula/Toastr/index")),
	},

	{
		path: "*",
		// layout: DashboardLayout,
		routes: [
			{
				component: () => <Redirect to="/auth/signin" />,
			},
		],
	},
];

export default routes;
