import "./LeftMenu.scss"
import { SideMenuItems } from "components/SideMenu/SideMenuItems"
import { useSelector } from "react-redux"
import SvgIcon from "components/SvgIcon"
import React from "react"
import useSize from "utils/useSize"
import { useHistory } from "react-router-dom"
import { t } from "i18next"
import { inactiveState } from "./MainLayout"

export default function LeftMenu({ state, setState }) {
    const user = useSelector(state => state?.user)
    const size = useSize()
    const history = useHistory()
    const isPhone = size === "md" || size === "sm"

    const hasPermission = ({ permission }) => {
        if (user?.type === "admin") return user?.permissions?.[permission] || permission === "any"
        return !permission
    }

    const menuItems = SideMenuItems.filter(hasPermission)

    return (
        <div className="LeftMenu relative z-10">
            {!isPhone && (
                <div>
                    {menuItems.map((x, i) => (
                        <div
                            key={i}
                            onClick={() => {
                                setState?.(prevState => ({ ...prevState, ...inactiveState }))
                                history.push(x.url)
                            }}
                            tooltip={t(x.name)}
                            className={`side-menu-item button-tool-tip-right ${window.location.pathname.endsWith(x.url) && "selected"}`}
                        >
                            <SvgIcon name={x.svg} style={x?.style} className="buttonSimple side-menu-icon" />
                        </div>
                    ))}
                </div>
            )}
            <PhoneSideMenu menuItems={menuItems} opened={state.sideMenuActive} setState={setState} />
        </div>
    )
}

function PhoneSideMenu({ menuItems, opened, setState }) {
    const history = useHistory()
    return (
        <div className={`dropdown-menu ${opened && "dropdown-menu-opened"}`}>
            {menuItems.map((x, i) => (
                <div
                    key={i}
                    onClick={() => {
                        setState?.(prevState => ({ ...prevState, ...inactiveState }))
                        history.push(x.url)
                    }}
                    tooltip={t(x.name)}
                    className={`side-menu-item button-tool-tip-right ${window.location.pathname.endsWith(x.url) && "selected"}`}
                >
                    <SvgIcon name={x.svg} style={x?.style} className="buttonSimple side-menu-icon" />
                    <div className="side-menu-label">{t(x.name)}</div>
                </div>
            ))}
        </div>
    )
}