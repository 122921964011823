// import orderTableData from "mockData/orderTableData";
import { apiAction, reDispatch } from "store/middleware/api";
import store from "../index";
import { setApiResponse } from "./auth";

export const SET_STATISTICS = "dataView/SET_STATISTICS";
export const CLEAR_STATISTICS = "dataView/CLEAR_STATISTICS";

const initialState = {
  statistics: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STATISTICS:
        return {...state, statistics: [...action.payload]};
    case CLEAR_STATISTICS:
        return {...initialState}
    default:
      return state;
  }
}

export const loadStatistics = (type) =>
  apiAction({
    endPoint: `/uniprest/${type}/statistics`,
    onSuccess: (data, dispatch) => {
      console.log("API Statistics", data);
      dispatch(setApiResponse(data));
      return {
        type: SET_STATISTICS,
        payload: data,
      };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

export const loadCompanyStatistics = () =>
  apiAction({
    endPoint: `/organization/webshop-client/statistics`,
    onSuccess: (data, dispatch) => {
      console.log("API Statistics", data);
      dispatch(setApiResponse(data));
      return {
        type: SET_STATISTICS,
        payload: data,
      };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

export const loadProductStatistics = () =>
  apiAction({
    endPoint: `/product/goods/statistics`,
    onSuccess: (data, dispatch) => {
      console.log("API Statistics", data);
      dispatch(setApiResponse(data));
      return {
        type: SET_STATISTICS,
        payload: data,
      };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });