import "./TopMenu.scss"
import moment from "moment"
import { setLang } from "store/modules/lang"
import { useSelector } from "react-redux"
import { useDispatch } from "store"
import { baseUrl, logout } from "store/apiActions"
import { useHistory } from "react-router-dom"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useTranslation } from "react-i18next"
import Logo from "components/Logo"

export default function TopMenu({ state, setState }) {
    const dispatch = useDispatch()
    const history = useHistory()

    const lang = useSelector(state => state?.lang?.lang)
    const userType = useSelector(state => state?.user?.type)
    const profileImg = useSelector(state => state?.user?.profile_image)
    const user = useSelector(state => state?.user)

    const langFunc = (changeLang) => {
        moment.locale(lang === "RO" ? "ro" : "hu")
        if (changeLang) dispatch(setLang(lang === "RO" ? "HU" : "RO"))
    }
    langFunc()

    const getAvatar = () => {
        if (!profileImg) {
            return <div className='rounded-full object-cover avatar-txt'>{user?.person?.first_name?.[0]} {user?.person?.last_name?.[0]}</div>
        } else {
            return <img
                width={32}
                height={32}
                className="rounded-full object-cover" src={baseUrl + profileImg}
                alt="" />
        }
    }

    return (
        <>
            <div className={`flex top-menu ${userType === "admin" ? "admin-colors" : "citizen-colors"}`}>
                <button type="button" name="hamburger" className={`hamburger ${state.sideMenuActive && "active"}`}
                        onClick={() => setState(x => ({ ...x, sideMenuActive: !x.sideMenuActive, blur: (!x.sideMenuActive || x.profileDropdownActive) }))}>
                    <span />
                </button>
                <div className="pointer" onClick={() => history.push(userType === "admin" ? "/web-admin" : "/dashboard")}>
                    <Logo />
                </div>
                <div className="hide-on-small-screen flex grow gap-4">
                    {/* <input type="text" placeholder={t("filter.search.placeholder")} className="ml-auto top-menu-search self-center" /> */}
                    <div id="langSwitch" className="nav-item self-center flex justify-center items-center aspect-square pointer ml-auto"
                         onClick={() => langFunc(true)}
                    >{lang}</div>
                    <div className="relative gray-box aspect-square">
                        <div
                            className="h-full flex justify-center items-center pointer"
                            onClick={() => setState(x => ({ ...x, profileDropdownActive: !x.profileDropdownActive, blur: (x.sideMenuActive || !x.profileDropdownActive) }))}>
                            {getAvatar()}
                        </div>
                        {state.profileDropdownActive && (
                            <ProfileDropdown />
                        )}
                    </div>
                </div>
            </div>
            <div className={`flex md:hidden top-menu gap-4 p-4 citizen-colors`}>
                <div className="ml-auto nav-item self-center flex justify-center items-center aspect-square pointer"
                     onClick={() => langFunc(true)}>
                    {lang}
                </div>
                <div className="relative gray-box aspect-square">
                    <div
                        className="h-full flex justify-center items-center pointer"
                        onClick={() => setState(x => ({ ...x, profileDropdownActive: !x.profileDropdownActive, blur: (x.sideMenuActive || !x.profileDropdownActive) }))}>
                        {getAvatar()}
                    </div>
                    {state.profileDropdownActive && (
                        <ProfileDropdown />
                    )}
                </div>
            </div>
        </>
    )
}

function ProfileDropdown() {
    const user = useSelector(state => state?.user)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return (
        <div className="absolute profile-dropdown flex flex-col" style={{minWidth: 230, minHeight: 100}}>
            <div>{t('logout.loggedAs')}</div>
            <div className="mb-auto">{user?.person?.first_name ?? ''} {user?.person?.last_name ?? user?.email}</div>
            <MagicButton h={5} label={t('logout.exit')} onClick={() => { dispatch(logout()) }} />
        </div>
    )
}
