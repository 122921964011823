import { useEffect, useLayoutEffect, useState } from "react";

const wait = null;
const throttleTimeout = null

function useWindowSize(ref=null) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    // console.log(windowSize)
    const element = ref?.current ?? window
    // console.log(element)

    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: ref ? element.clientWidth: window.innerWidth,
        height: ref ? element.clientHeight: window.innerHeight,
      });
    }

    useEffect(() => {
      // Handler to call on window resize
    
      

      // Add event listener
      window.addEventListener("resize", handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, [ref?.current]); // Empty array ensures that effect is only run on mount

    useLayoutEffect(() => {
      const handleScroll = () => {
        if (wait) {
          if (throttleTimeout === null) {
            throttleTimeout = setTimeout(handleResize, wait)
          }
        } else {
          handleResize()
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }, [])
  
    return windowSize;
  }

export default useWindowSize;