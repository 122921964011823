const TOGGLE_LANGUAGE = "user/TOGGLE_LANGUAGE"
const SET_USER_INFO = "user/SET_USER_INFO"
const SET_USER_DATA = 'user/SET_USER_DATA';
const SET_USER_ARTICLES = 'user/SET_USER_ARTICLES';
const SET_USER_ONLINE_APPLICATIONS = 'user/SET_USER_ONLINE_APPLICATIONS';

const initialState = {
    language: 'EN',
    id: null,
    type: null,
    status: null,
    email: null,
    profile_image: null,
    created_at: null,
    updated_at: null,
    person: null,
    permissions: null,
    articles: [],
    onlineApplications: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_LANGUAGE:
            return {
                ...state,
                language: state.language === "EN" ? "RO" : "EN"
            }
        case SET_USER_INFO:
            return {
                ...state,
                ...action.payload,
            }
        case SET_USER_DATA:
            return {
                ...initialState,
                ...action.payload
            }
        case SET_USER_ARTICLES:
            return {
                ...state,
                articles: action.payload
            }
        case SET_USER_ONLINE_APPLICATIONS:
            return {
                ...state,
                onlineApplications: action.payload
            }
        default:
            return state;
    }
}

export const setUserOnlineApplications = (data) => ({
    type: SET_USER_ONLINE_APPLICATIONS,
    payload: data
})

export const setUserArticles = (data) => ({
    type: SET_USER_ARTICLES,
    payload: data
})

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data
})

export const toggleLanguage = () => ({
    type: TOGGLE_LANGUAGE
})

export const setUserInfo = (data) => ({
    type: SET_USER_INFO,
    payload: data,
})