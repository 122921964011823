// const ADD_TO_CART = "shop/ADD_TO_CART"
// const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING"


const invoiceCard = {
  created: "EMISA PE",
  title: "Factura Fiscala",
  payByCard: "Plateste cu cardul",
  toPay: "De achitat pana pe",
  forOrder: "Aferenta comenzii",
  download: "Download",
  paidMessage: "Achitata. Multumim",
  notPaidMessage: "Factura este restanta",
}
const orderCard = {
  placedOn: 'PLASATA PE',
  order: 'Comanda',
  productsOne: '# Produs',
  productsMulti: '# Produse',
  total: "Total",
  report: "RAPORTEAZA O PROBLEMA",
  delivery: ['livrare', 'livrari'],
}

const listCard = {
  yourTitle: "Lista Ta",
  otherTitle: "Lista",
  productOne: " PRODUS",
  productMulti: " PRODUSE",
  updatedOn: "ACTUALIZATA PE",

}

const initialState = {
  components:{ 
    invoiceCard,
    orderCard,
    listCard,
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
      default:
          return state;
  }
}

