import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setEmailError, setPasswordError } from 'store/modules/auth';
import { setLang } from 'store/modules/lang';
import { useTranslation } from "react-i18next"
import './index.scss';
import bgImg from 'assets/authlayout/background.png'
import Logo from "components/Logo"

export default function AuthLayout({ children }) {
	const dispatch = useDispatch();
	const lang = useSelector(state => state?.lang?.lang)
	const { t } = useTranslation()
	const location = useLocation();

	const logo = (
		<div className='logoContainer'>
			<Logo />
		</div>
	)

	dispatch(setEmailError(null))
	dispatch(setPasswordError(null))

	return (
		<div className='layout'>
			<header className='authNavbar'>
				<div className='left-items'>
					{logo}
				</div>
				<div className='right-items'>
					<div className="lang-box" onClick={() => dispatch(setLang(lang === 'RO' ? 'HU' : 'RO'))}>{lang}</div>
				</div>
			</header>
			<div className={`background-wrap ${location?.pathname === "/auth/signup" ? 'background-wrap-signup' : ''}`}>
				{location?.pathname !== "/auth/signup" && <div className='backround-image w-full h-full'>
					<img className="w-full h-full" src={bgImg} alt="" />
				</div>}
				<div className='centered-child relative'>
					<div>{children}</div>
				</div>
			</div>

		</div>
	);
}
