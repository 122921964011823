import MagicButton from "components/MagicButton/MagicButtonV2"
import SvgIcon from "components/SvgIcon/SvgIcon"
import BigModal from "components/_modals/BigModal"
import { useDispatch, useSelector } from "react-redux"
import { setPageModal } from "store/modules/page"
import "./confirmationActionModal.scss"
import { loc } from "utils/Localize"
import { t } from "i18next"
import useSize from "utils/useSize"



function ConfirmActionModal(props) {

  const dispatch = useDispatch()
  const actionToConfirm = useSelector(state => state.page?.actionToConfirm)
  const modalData = useSelector(state => state.page?.modalData)
  const { message, svg, svgName, closeAction,
    primaryLabel,
    primaryAction,
    secondaryLabel,
    secondaryAction,
  } = modalData ?? {}

  const onClose = () => {
    dispatch(setPageModal())
    if (closeAction) closeAction()
    if (secondaryAction) secondaryAction()
  }
  const onConfirm = () => {
    if (actionToConfirm) {
      actionToConfirm()
    }
    if (primaryAction) {
      primaryAction()
    }
    dispatch(setPageModal())
  }
  const name = String(modalData?.name)?.split(' ')?.slice(0, 2)?.map(i => i[0]?.toUpperCase())?.join('')

  const size = useSize()
  const isPhone = size === "sm" || size === "md"

  return (
    <BigModal noFooter name="SYS-confirm-action" title="" className="sys-confirm-action" >
      {svg ??
        (svgName ? <SvgIcon name={svgName} />
          : !name ? defaultIllustration : (
            <div className='custom-circle-avatar bg-red-1'>{name}</div>
          ))
      }
      <span className="etype-2 message">
        {message ?? t("modal.defaultMessage")}
      </span>
      <div className={`${!isPhone ? 'flex' : 'conf-buttons'}`}>
        <MagicButton w={27} h={6.5} className={'button-customize-outline'} label={secondaryLabel ?? t("modal.cancel")} outline mr={3}
          onClick={onClose}
        />
        <MagicButton w={27} h={6.5} className={'button-customize'} label={primaryLabel ?? t("modal.confirm")}
          onClick={onConfirm}
        />
      </div>
    </BigModal>
  )
}

export default ConfirmActionModal

const defaultIllustration = <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="120" cy="120" r="120" fill="#EDF5FF" />
  <path d="M93.3182 109.513H96.5582V123.513H93.3182V109.513ZM99.8807 109.513H103.121V120.873H110.141V123.513H99.8807V109.513ZM111.951 109.513H115.191V120.873H122.211V123.513H111.951V109.513ZM130.066 123.753C128.066 123.753 126.506 123.199 125.386 122.093C124.279 120.986 123.726 119.406 123.726 117.353V109.513H126.966V117.233C126.966 119.739 128.006 120.993 130.086 120.993C131.099 120.993 131.872 120.693 132.406 120.093C132.939 119.479 133.206 118.526 133.206 117.233V109.513H136.406V117.353C136.406 119.406 135.846 120.986 134.726 122.093C133.619 123.199 132.066 123.753 130.066 123.753Z" fill="black" />
</svg>
