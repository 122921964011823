const SET_PAGE_TABS = "SET_PAGE_TABS";
const ADD_PAGE_TAB = "ADD_PAGE_TAB";
const REMOVE_PAGE_TAB = "REMOVE_PAGE_TABS";
const SELECT_PAGE_TAB = "SELECT_PAGE_TABS";

const initialState = {
  tabs: [],
  selectedPageId: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_TABS:
      return {
        tabs: [...action.payload],
      };
    case ADD_PAGE_TAB:
      return {
        ...state,
        tabs: [...state.tabs, action.payload],
      };
    case REMOVE_PAGE_TAB:
      return {
        ...state,
        tabs: state?.tabs && state.tabs.filter(tab => tab.pageId !== action.payload),
      };
    case SELECT_PAGE_TAB:
      return {
        ...state,
        selectedPageId: action.payload,
      };
    default:
      return state;
  }
}

export const setPageTabs = (tabs) => {
  return {
    type: SET_PAGE_TABS,
    payload: tabs,
  };
};

export const addPageTab = (tab) => {
  return {
    type: ADD_PAGE_TAB,
    payload: tab,
  };
};

export const removePageTab = (pageId) => {
  return {
    type: REMOVE_PAGE_TAB,
    payload: pageId,
  };
};

export const selectPageTab = (pageId) => {
  return {
    type: SELECT_PAGE_TAB,
    payload: pageId,
  };
};