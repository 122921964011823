/* eslint-disable import/no-anonymous-default-export */
// import store from "store";

// import store from "store";

export const SET_COLLAPSED_MENU = "page/SET_COLLAPSED_MENU"
export const SET_CRT_LIST_ITEMS = "page/SET_CRT_LIST_ITEMS"
export const ADD_CRT_LIST_ITEMS = "page/ADD_CRT_LIST_ITEMS"
export const SET_LEFT_MENU_ITEMS = "page/SET_LEFT_MENU_ITEMS"
export const SET_PAGE_MODAL_DATA = "page/SET_PAGE_MODAL_DATA"
export const SET_PAGE_MODAL = "page/SET_PAGE_MODAL"
export const SET_PAGE_EDIT = "page/SET_PAGE_EDIT"
export const SET_WIZZARD_STEP = "page/SET_WIZZARD_STEP"
export const RESET_CRT_LIST = "page/RESET_CRT_LIST"
export const SET_CRT_LIST_SEARCH_STRING = "page/SET_CRT_LIST_SEARCH_STRING"
export const SET_CRT_FORM_ITEM = "page/SET_CRT_FORM_ITEM"
export const SET_SCROLL_POSITION = "page/SET_SCROLL_POSITION"
export const SET_ACTION_TO_CONFIRM = "page/SET_ACTION_TO_CONFIRM"
export const SET_TEMP_PRODUCT_FIX = "page/SET_TEMP_PRODUCT_FIX"
export const SET_PAGE_LIST_DATA = "page/SET_PAGE_LIST"
export const ADD_PAGE_LIST_DATA = "page/ADD_PAGE_LIST"
export const DELETE_PAGE_LIST_ITEM = "page/DELETE_PAGE_LIST_ITEM"
export const TOGGLE_PROFILE_POPUP = "page/TOGGLE_PROFILE_POPUP"
export const SET_DEPARTMENTS = "page/SET_DEPARTMENTS"

const initialcrtList = {
    items: [],
    lastItemHint: null,
    searchString: ""
}
const initialState = {
    collapsedMenu: false,
    isEdit: false,
    crtFormItem: undefined,
    actionToConfirm: undefined,
    modal: undefined,
    modalData: undefined,
    crtList: initialcrtList,
    wizzardStep: 1,
    tempProductFix: {},
    isProfilePopupActive: false,
    departments: []
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_TEMP_PRODUCT_FIX:
            return {
                ...state,
                tempProductFix: action.payload
            }
        case SET_WIZZARD_STEP:
            return {
                ...state,
                wizzardStep: action.payload
            }
        case SET_ACTION_TO_CONFIRM:
            return {
                ...state,
                actionToConfirm: action.payload,
                modal: action.payload ? "SYS-confirm-action" : undefined
            }
        case SET_CRT_FORM_ITEM:
            return {
                ...state,
                crtFormItem: action.payload.combine
                    ? { ...state.crtFormItem, ...action.payload.data }
                    : action.payload.data
            }
        case SET_PAGE_MODAL:
            return {
                ...state,
                modal: action.payload
            }
        case SET_PAGE_MODAL_DATA:
            return {
                ...state,
                modalData: action.payload
            }
        case SET_PAGE_EDIT:
            return {
                ...state,
                isEdit: action.payload ?? false
            }
        case SET_LEFT_MENU_ITEMS:
            return {
                ...state,
                leftMenuItems: action.payload
            }
        case SET_CRT_LIST_SEARCH_STRING:
            return {
                ...state,
                crtList: {
                    ...state.crtList,
                    searchString: action.payload
                }
            }
        case SET_CRT_LIST_ITEMS:
            return {
                ...state,
                crtList: {
                    ...state.crtList,
                    items: action.payload.items,
                    lastItemHint: action.payload.lastItemHint
                }
            }
        case ADD_CRT_LIST_ITEMS:
            return {
                ...state,
                crtList: {
                    ...state.crtList,
                    items: [...state.crtList.items, ...action.payload.items],
                    lastItemHint: action.payload.lastItemHint
                }
            }
        case RESET_CRT_LIST:
            return {
                ...state,
                crtList: initialcrtList
            }
        case SET_COLLAPSED_MENU:
            return {
                ...state,
                collapsedMenu: action.payload
            }
        case SET_SCROLL_POSITION:
            return {
                ...state,
                setScrollPosition: action.payload
            }
        case SET_PAGE_LIST_DATA:
            return {
                ...state,
                pageListData: action.payload
            }
        case ADD_PAGE_LIST_DATA:
            return {
                ...state,
                pageListData: {
                    ...action.payload,
                    data: [
                        ...state?.pageListData?.data ?? [],
                        ...action.payload?.data ?? []
                    ]
                }
            }
        case DELETE_PAGE_LIST_ITEM:
            return {
                ...state,
                pageListData: {
                    ...state?.pageListData,
                    ...action.payload,
                    data: state?.pageListData?.data?.filter(action.payload)
                }
            }
        case TOGGLE_PROFILE_POPUP:
            return {
                ...state,
                isProfilePopupActive: !state.isProfilePopupActive
            }
        case SET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload
            }
        default:
            return state
    }
}


export const setTempProductFix = (data) => ({
    type: SET_TEMP_PRODUCT_FIX,
    payload: data
})
export const setActionToConfirm = (data) => ({
    type: SET_ACTION_TO_CONFIRM,
    payload: data
})

export const setPageModal = (data) => ({
    type: SET_PAGE_MODAL,
    payload: data
})
export const setPageModalData = (data) => ({
    type: SET_PAGE_MODAL_DATA,
    payload: data
})
export const setLeftMenuItems = (data) => ({
    type: SET_LEFT_MENU_ITEMS,
    payload: data
})
export const setWizzardStep = (data) => ({
    type: SET_WIZZARD_STEP,
    payload: data
})
export const resetCrtList = (data) => ({
    type: RESET_CRT_LIST,
    payload: data
})

export const setCrtList = (data) => ({
    type: SET_CRT_LIST_ITEMS,
    payload: data
})

export const setCrtListSearchString = (data) => ({
    type: SET_CRT_LIST_SEARCH_STRING,
    payload: data
})


export const setCrtFormItem = (data, combine) => ({
    type: SET_CRT_FORM_ITEM,
    payload: { data, combine }
})

export const setPageEdit = (data) => ({
    type: SET_PAGE_EDIT,
    payload: data
})

export const setCollapsedMenu = (flag) => ({
    type: SET_COLLAPSED_MENU,
    payload: flag
})

export const setScrollPostion = (data) => ({
    type: SET_SCROLL_POSITION,
    payload: data
})

export const setPageListData = (data) => ({
    type: SET_PAGE_LIST_DATA,
    payload: data
})

export const addPageListData = (data) => ({
    type: ADD_PAGE_LIST_DATA,
    payload: data
})

export const deletePageListItem = (func) => ({
    type: DELETE_PAGE_LIST_ITEM,
    payload: func
})
export const toggleProfilePopup = () => ({
    type: TOGGLE_PROFILE_POPUP
})
export const setDepartments = (departments) => ({
    type: SET_DEPARTMENTS,
    payload: departments
})