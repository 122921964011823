import { apiAction } from "store/middleware/api"

import { history } from 'utils/history';

import {
    setRoles,
    setForms,
    setCounties,
    setPermissionsName, setPermissionsList, setLocalities
} from "store/modules/admin"


import { addPageListData, deletePageListItem, setCrtFormItem, setPageListData } from "./modules/page"
import { toastr } from "react-redux-toastr"
import { setDataItems } from "./modules/dataView"
import store from "./index"
import { setUserArticles, setUserData, setUserOnlineApplications } from "./modules/user";
import { loginUser, logoutUser, setEmailError, setPasswordError } from "./modules/auth";
import { setCheckedFormName, setFormCategories, setFormDepartments, setFormTypes } from "./modules/formBuilder"
import parsePermissions from "utils/parsePermissions"
import { t } from "i18next"
import { successMessage } from "../views/onlineForms/utils"

export const baseUrl = process.env.REACT_APP_API_ENDPOINT
const env = process.env.REACT_APP_ENV
const reactAppBasenamePrefix = process.env.REACT_APP_BASENAME_PREFIX

export const saveFormBuilder = (data) => {
    data.category_document_type_id = data?.form_type_id
    delete data?.form_type_id
    return apiAction({
        endPoint: "/api/online-forms",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (response) => {

            const { success } = response
            const physical_documents = response?.physical_documents
            const wrongDocumentType = response?.['physical_documents.0.file']

            if (success) {
                successMessage()
                const redirectUrl = `${reactAppBasenamePrefix}web-admin/form-list`
                history.push(redirectUrl)
            }
            else if (physical_documents !== undefined) toastr.error('Physical doc must be uploaded!')
            else if (wrongDocumentType !== undefined) toastr.error('The files must be of type: pdf, doc, docx, jpg, png!')
            return { type: "a" }
        },
        onFailure: e => {
            return {
                type: "a"
            }
        }
    })
}

export const updateOnlineForm = (data, id) => {
    data.category_document_type_id = data?.form_type_id
    delete data?.form_type_id
    return apiAction({
        endPoint: `/api/online-forms/${id}`,
        method: "PUT",
        data,
        baseUrl,
        onSuccess: (data) => {
            const { success, physical_documents } = data
            if (success) {
                toastr.success("Form updated successfully!")
                const redirectUrl = `${reactAppBasenamePrefix}web-admin/form-list`
                history.push(redirectUrl)
            }
            else if (physical_documents) toastr.error('Physical doc is required!')
            return { type: "a" }
        },
        onFailure: e => {
            return {
                type: "a"
            }
        }
    })
}


export const saveOrUpdateCitizenFormData = (method, id, data) => getDataAsync(`/api/online-applications/${id}`, method, data)


export const sendCitizenFormData = ({ files, id, setIsLoading }) => {
    setIsLoading?.(true)
    return apiAction({
        endPoint: `/api/send/online-applications/${id}`,
        method: "POST",
        data: { files },
        baseUrl,
        onSuccess: (responseData, dispatch) => {
            const { success, data } = responseData
            const { registration_number, registration_date } = data
            if (success) {
                successMessage()
                history.push({
                    pathname: `${reactAppBasenamePrefix}dashboard/online-forms/request-confirmation`,
                    state: { regNr: registration_number, regDate: registration_date }
                })
            } else setIsLoading?.(false)
            return { type: "a" }
        },
        onFailure: e => {
            setIsLoading?.(false)
            return {
                type: "a"
            }
        }
    })
}

export const sendCitizenFormDataFlow3 = ({ data, id }) => getDataAsync(`/api/online-applications/flux-3/${id}`, 'POST', data)
    .then(response => {
        const { success, data } = response
        const { id, registration_number, registration_date } = data ?? {}
        if (success) {
            successMessage()
            history.push({
                pathname: `${reactAppBasenamePrefix}dashboard/online-forms/request-confirmation`,
                state: { id, regNr: registration_number, regDate: registration_date }
            })
        } else {
            if (Object.values(response).some(x => x?.[0] === 'The file must be a file of type: pdf')) {
                toastr.error(t('toastr.requiredPdfDoc'))
            }
        }
    })

export const citizenOnlineFormRating = (data) =>
    apiAction({
        endPoint: `/api/citizen-feedbacks`,
        method: "POST",
        data,
        baseUrl,
        onSuccess: (responseData, dispatch) => {

            const { success } = responseData
            if (success) {
                toastr.success(t('citizen.onlineForms.feedBackSaved'))
                history.push(`${reactAppBasenamePrefix}dashboard/online-applications`)
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getFormCategories = data =>
    apiAction({
        endPoint: "/api/form-categories",
        method: "GET",
        data,
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: formCategories } = data
            if (success) dispatch(setFormCategories(formCategories))
            return { type: "a" }
        },
        onFailure: e => {



            return {
                type: "a"
            }
        }
    })


export const getFormTypes = data =>
    apiAction({
        endPoint: "/api/form-category-document-type",
        method: "GET",
        data,
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: formTypes } = data
            if (success) dispatch(setFormTypes(formTypes))
            return { type: "a" }
        },
        onFailure: e => {



            return {
                type: "a"
            }
        }
    })


export const getFormDetails = (formId) => getDataAsync(`/api/online-forms/${formId}`).then(({data}) => data)


export const getCounties = data =>
    apiAction({
        endPoint: "/api/counties",
        method: "GET",
        data,
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: counties } = data
            if (success) return dispatch(setCounties(counties))
            else return { type: "a" }
        },
        onFailure: e => {





            return {
                type: "a"
            }
        }
    })

export const login = (data, t) =>
    apiAction({
        endPoint: "/api/login",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (responseData, dispatch) => {
            const { success, data, password } = responseData;

            if (password) {
                dispatch(setEmailError(null))
                dispatch(setPasswordError(t('authCard.minCharacters')))
            }

            if (success) {
                const { token, user } = data;
                const tokenData = { token };

                dispatch(setEmailError(null)); dispatch(setPasswordError(null));
                dispatch(setUserData({ ...data?.user, ...parsePermissions(data) }))
                dispatch(loginUser(tokenData))
                history.push(`${reactAppBasenamePrefix}${user.type === 'citizen' ? 'dashboard' : 'web-admin'}`)
            } else {
                if (data === 'Invalid credentials') {
                    dispatch(setEmailError(t('authCard.invalidCredentials')))
                    dispatch(setPasswordError(t('authCard.invalidCredentials')))
                } else if (data === 'User is suspended or canceled' || data === "You can't logg into your account") {
                    toastr.error(t('authCard.accountSuspended'))
                }
            }

            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e) => {
            return {
                type: "a"
            }
        }
    })

export const resetPassword = (data, setSendStatus) =>
    apiAction({
        endPoint: "/api/password/reset",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (data) => {
            const { success } = data
            if (success) setSendStatus(true)
            else toastr.error(t('resetPasswordCard.emailNotFoundToast'))
            return {
                type: "a",
            }
        },
        onFailure: (e) => {
            return {
                type: "a"
            }
        }
    })

export const logout = () =>
    apiAction({
        endPoint: "/api/logout",
        method: "POST",
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success } = data

            dispatch(logoutUser())
            if (success) history.push(reactAppBasenamePrefix)
            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e) => {


            return {
                type: "a"
            }
        }
    })

export const getRoleList = () =>
    apiAction({
        endPoint: "/api/roles",
        method: "GET",
        baseUrl,
        onSuccess: (responseData, dispatch) => {
            const { success, data } = responseData

            if (success) return dispatch(setRoles(data))
            else return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getRole = id =>
    apiAction({
        endPoint: `/api/roles/${id}`,
        method: "GET",
        baseUrl,
        onSuccess: (responseData, dispatch) => {
            const { data } = responseData
            return dispatch(setCrtFormItem(data))
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const createAdminUser = (data, setEmailError) =>
    apiAction({
        endPoint: "/api/admins",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success } = response
            if (response?.email) setEmailError(true)
            if (success) {
                toastr.success(t("toastr.success"))
                setTimeout(() => {
                    history.goBack()
                }, 800)
            } else {
                toastr.error(t("toastr.error"))
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const updateAdminUser = (data, id) =>
    apiAction({
        endPoint: "/api/admins/" + id,
        method: "PUT",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success } = response
            if (success) history.goBack()
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getAdminUserDetails = userId =>
    apiAction({
        endPoint: `/api/admins/${userId}`,
        method: "GET",
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: user } = data

            if (success) return dispatch(setCrtFormItem(user))
            else return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })


export const getAdminUserList = data => {
    return apiAction({
        endPoint: `/api/admins`,
        method: "GET",
        baseUrl,
        data,
        onSuccess: (data, dispatch) => {

            const { success, data: admins } = data
            if (success) return dispatch(setDataItems(admins))
            else return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })
}

export const deleteAdminUser = id =>
    apiAction({
        endPoint: `/api/admins/${id}`,
        method: "DELETE",
        baseUrl,
        onSuccess: (data) => {
            if (data.success) {
                return deletePageListItem(x => x.id !== id)
            }
            return { type: "a" }
        },
        onFailure: e => ({ type: "a" })
    })


export const getTableData = (data, isLoadMore) => {
    store.dispatch(
        apiAction({
            method: "GET",
            baseUrl,
            ...data,
            onSuccess: (data, dispatch) => {
                if (data.success) return (isLoadMore ? addPageListData : setPageListData)(data)
                return setPageListData(data)
            },
            onFailure: e => ({ type: "a" })
        })
    )
}

export const deleteRole = (id) =>
    apiAction({
        endPoint: `/api/roles/${id}`,
        method: "DELETE",
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success } = response;

            if (success) {
                return deletePageListItem(x => x.id !== id)
            } else {
                toastr.error('Acest rol nu poate fi șters pentru că are utilizatori asociați!')
            }
            return { type: "a" }
        },
        onFailure: e => {
            return {
                type: "a"
            }
        }
    })

export const getCitizenFeedbackDetails = id =>
    apiAction({
        endPoint: `/api/citizen-feedbacks/${id}`,
        method: "GET",
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: details } = data
            if (success) return dispatch(setCrtFormItem(details))
            else return { type: "a" }
        },
        onFailure: e => {
            return {
                type: "a"
            }
        }
    })


export const getUserData = () => {
    return apiAction({
        endPoint: "/api/me",
        method: "POST",
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success, data } = response;

            if (success) {
                return dispatch(setUserData({ ...data, ...parsePermissions(data) }))
            }
        },
        onFailure: e => {
            return {
                type: "a"
            }
        }
    })
}

export const getUserOnlineApplications = () => {
    return apiAction({
        endPoint: "/api/online-applications",
        method: "GET",
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success, data } = response;

            if (success) return dispatch(setUserOnlineApplications(data));
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })
}

export const createArticle = (data) => getDataAsync('/api/articles', "POST", data)
    .then(({ success }) => success && history.goBack())

export const updateArticle = (id, data) => getDataAsync(`/api/articles/${id}`, "POST", data)
    .then(({ success }) => success && history.goBack())


export const getArticles = () => {
    return apiAction({
        endPoint: "/api/articles",
        method: "GET",
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success, data } = response;

            if (success) return dispatch(setUserArticles(data));
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })
}

export const getPermissionsName = data =>
    apiAction({
        endPoint: "/api/permissions-names",
        method: "GET",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success, data } = response

            if (success) {
                dispatch(setPermissionsName(data));
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getPermissionsList = data =>
    apiAction({
        endPoint: "/api/permissions",
        method: "GET",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {
            const { success, data } = response

            if (success) {
                dispatch(setPermissionsList(data));
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const updateRole = (body, id) =>
    apiAction({
        endPoint: "/api/roles/" + id,
        method: "PUT",
        body,
        baseUrl,
        onSuccess: (response, dispatch) => {
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const changeUserPassword = (body, id) =>
    apiAction({
        endPoint: `/api/reset-password/${id}`,
        method: "POST",
        body,
        baseUrl,
        onSuccess: (response, dispatch) => {

            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

const userActionBase = (endPoint, id) => apiAction({
    endPoint: endPoint + id,
    method: "POST",
    baseUrl,
    onSuccess: (response, dispatch) => {
        history.goBack()
        return { type: "a" }
    },
    onFailure: e => {


        return {
            type: "a"
        }
    }
})

export const cancelUser = (id) => userActionBase('/api/cancel-user/', id)
export const suspendUser = (id) => userActionBase('/api/suspend-user/', id)
export const activateUser = (id) => userActionBase('/api/activate-user/', id)
export const acceptUser = (id) => userActionBase('/api/accept-user/', id)

export const resendActivationLink = (data) =>
    apiAction({
        endPoint: "/api/resend-activation-link",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {
            if (response?.success) toastr.success(t('admin.userList.form.emailSuccessSentMsg')?.replace('{mail}', data?.email))
            history.goBack()
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })


export const createCitizen = (data, successFunc) =>
    apiAction({
        endPoint: "/api/citizens",
        method: "POST",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {


            successFunc?.(response)
            if (response?.success)
                history.goBack()
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const updateCitizen = (data, id, successFunc) =>
    apiAction({
        endPoint: "/api/citizens/" + id,
        method: "PUT",
        data,
        baseUrl,
        onSuccess: (response, dispatch) => {


            if (successFunc) {
                successFunc(response)
            }

            if (response?.success)
                history.goBack()
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getUserDetails = userId =>
    apiAction({
        endPoint: `/api/citizens/${userId}`,
        method: "GET",
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: user } = data
            if (success) return dispatch(setCrtFormItem(user))
            else return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getDataAsync = async (endPoint, method, bodyData) => {
    return await new Promise(resolve => {
        const func = (data) => { resolve(data); return { type: 'a' } }
        store.dispatch(apiAction({
            endPoint,
            baseUrl,
            method,
            data: bodyData,
            onSuccess: func,
            onFailure: func
        }))
    })
}


export const deleteOnlineForm = (id) => getDataAsync('/api/online-forms/' + id, 'DELETE')
    .then(({ success }) => success && window.location.reload())


export const getRepresentativeData = data =>
    apiAction({
        endPoint: `/api/representatives/${data.id}/${data.type}`,
        method: "GET",
        baseUrl,
        onSuccess: (data, dispatch) => {
            const { success, data: user } = data

            if (success) return dispatch(setCrtFormItem(user))
            else return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const deleteArticle = (id) => getDataAsync('/api/articles/' + id, 'DELETE')
    .then(({ success }) => success && store.dispatch(deletePageListItem(x => x.id !== id)))

export const getArticle = (id) => getDataAsync(`/api/articles/${id}`).then(data => {
    if (data.success) return data.data
    toastr.error()
})




export const addOnlineFormFile = (data, id, refresh) =>
    apiAction({
        endPoint: `/api/online-applications/${id}/documents`,
        method: "POST",
        data,
        baseUrl,
        onSuccess: (responseData, dispatch) => {

            const { success } = responseData
            if (success) {
                toastr.success(t('toastr.docAddedSuccess'))
                refresh()
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const deleteOnlineFormFile = (data, id, refresh) =>
    apiAction({
        endPoint: `/api/online-applications/documents/${id}`,
        method: "DELETE",
        data,
        baseUrl,
        onSuccess: (responseData, dispatch) => {

            const { success } = responseData
            if (success) {
                toastr.success(t('toastr.docDeletedSuccess'))
                refresh()
            }
            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const deleteOnlineFormDocument = ({ formId, path }) =>
    apiAction({
        endPoint: `/api/online-forms/documents/${formId}?path=${path}`,
        method: "DELETE",
        baseUrl,
        onSuccess: (responseData, dispatch) => {

            const { success } = responseData
            console.log("responseData", responseData)
            return { type: "a" }
        },
        onFailure: e => {
            console.log("e", e)

            return {
                type: "a"
            }
        }
    })

export const checkFormName = ({ data, isNameAvailable, endPoint, setError }) =>
    apiAction({
        endPoint,
        method: "GET",
        baseUrl,
        data,
        onSuccess: (responseData, dispatch) => {

            const { success } = responseData

            if (success) {
                isNameAvailable(true)
                dispatch(setCheckedFormName(data?.form_name))
            }
            else {
                isNameAvailable(false)
                setError('formName', { type: 'custom', message: 'Numele formularului exista deja!' });
            }

            return { type: "a" }
        },
        onFailure: e => {


            return {
                type: "a"
            }
        }
    })

export const getLocalities = () =>
    apiAction({
        endPoint: "/api/localities",
        method: "GET",
        baseUrl,
        onSuccess: (responseData, dispatch) => {

            const { success, data } = responseData

            console.log("getLocalities responseData", responseData)

            if (success) {
                dispatch(setLocalities(data))
            }

            return { type: "a" }
        },
        onFailure: e => {

            console.log("getLocalities error", e)

            return {
                type: "a"
            }
        }
    })

export const checkResetOrActivateToken = (email, token) => getDataAsync('/api/check-token', 'POST', {email, token})

export const getRepresentativeDetails = (id, type) => getDataAsync(`/api/representatives/${id}/${type}`).then(({data}) => data)