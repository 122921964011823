import { history } from "utils/history";
import { API, apiAction } from "../middleware/api";
import AWS from 'aws-sdk'
import {userLoggedIn} from 'App'
import { Auth } from "aws-amplify";
import { toastr } from "react-redux-toastr";
import { loadDictionary, loadEnums, loadProductCategories, loadValidationRules } from "./backData";
// import { getAllProducts } from "./menu";
export const LOGIN_USER = "LOGIN_USER";
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "auth/LOGOUT_USER";
export const SET_ONBOARDING = "auth/SET_ONBOARDING";
export const SET_API_RESPONSE = "auth/SET_API_RESPONSE";
export const SET_AUTHENTICATED = "auth/SET_AUTHENTICATED";
export const REFRESH_TOKENS = "auth/REFRESH_TOKENS";
export const SET_EMAIL_ERROR = "auth/SET_EMAIL_ERROR";
export const SET_PASSWORD_ERROR = "auth/SET_PASSWORD_ERROR";

const initialState = {
    authenticated: false,
    token: "",
    idToken: "",
    email: "",
    userData: {},
    apiResponse: null,
    emailError: null,
    passwordError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                authenticated: true,
                token: action.payload.token,
            };
        case REFRESH_TOKENS:
            return {
                ...state,
                token: action.payload.authToken,
                idToken: action.payload.authToken,
                refreshToken: action.payload.refreshToken,
            };
        case SET_USER:
            return { 
                ...state, 
                userData: action.payload 
            };
        case SET_AUTHENTICATED:
            return { 
                ...state, 
                authenticated: action.payload 
            };
        case SET_API_RESPONSE:
            return { 
                ...state, 
                apiResponse: action.payload 
            };
        case LOGOUT_USER:
            return { ...initialState };
        case SET_EMAIL_ERROR:
          return {
            ...state,
            emailError: action.payload
          }
        case SET_PASSWORD_ERROR:
          return {
            ...state,
            passwordError: action.payload
          }
    default:
      return state;
  }
}


export const resetPassword = email => {
  return async dispatch => {
    await Auth.forgotPassword(email);
  };
};

export const resetPasswordConfirm = (username, code, password) => {
  return async dispatch => {
    await Auth.forgotPasswordSubmit(username, code, password);
    dispatch(loginUser(username, password));
  };
};

export const setEmailError = (data) => {
  return {
    type: SET_EMAIL_ERROR,
    payload: data
  }
}

export const setPasswordError = (data) => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: data
  }
}

export const loginUser = (data) => {
    return {
      type: LOGIN_USER,
      payload: data
    };
  };

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};


export const clearApiResponse = () => {
    return {
        type: SET_API_RESPONSE,
        payload: null,
    };
};

export const setApiResponse = (data) => {
    return {
        type: SET_API_RESPONSE,
        payload: data,
    };
};


