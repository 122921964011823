import { apiAction } from "store/middleware/api";
import { setApiResponse } from "./auth";

const SET_CLIENT_TYPE = "company/SET_CLIENT_TYPE";
const SET_CLIENT_ADDRESSES = "company/SET_CLIENT_ADDRESSES";

let mock_invoicingData = [
  {
    id: "CDT-12341234",
    comanyName: "Nume Companie 1",
    cui: "RO 123456789",
    j: "J40 / 42 / 2015",
    address: "Str. Ceahlau 25, S2, Bucuresti"
  },
  {
    id: "CDT-12341235",
    comanyName: "Nume Companie 2",
    cui: "RO 123456789",
    j: "J40 / 42 / 2015",
    address: "Str. Ceahlau 25, S2, Bucuresti"
  }
];

let mock_deliveryData = [
  {
    id: "DEL-12341234",
    name: "Adresa primara",
    address: "43 Str. Truly Longname the second, Tirgu Mures, Mures, Romania - 022145. Poarta rosie, cladirea a 2-a pe dreapta, firma Alunelu SRL."
  },
  {
    id: "DEL-12341235",
    name: "Sediul 2",
    address: "43 Str. Truly Longname the second, Tirgu Mures, Mures, Romania - 022145. Poarta rosie, cladirea a 2-a pe dreapta, firma Alunelu SRL."
  }
];

let mock_paymentData = [
  {
    id: "PMD-0000wire",
    type: "wire",
    name: "Acum, prin transfer bancar",
  },
  {
    id: "PMD-0000card",
    type: "card",
    name: "Plata prin card Bancar",
    description: "Platesiti in siguranta prin Stripe, stocul rezervat instant, iar comanda este procesata in cateva minute."
  },
  {
    id: "PMD-0000date",
    type: "date",
    blue: 0.25,
    green: 0.6,
    limit: 56000,
    remainingLimit: 56000,
    currency: "EUR",
    name: "Factura la termen",
    
  },
];

let mock_lastOrders = [
  {
    id: "pr-01",
    date: "2021-04-20T11:30:58+03:00",
    brandName: "Portelan inc.",
    title: "Gresie portelanata Gri",
    invoiceId: "invoice-00158",
    price: 110.11
  },
  {
    id: "pr-02",
    date: "2021-04-20T11:30:58+03:00",
    brandName: "Portelan inc.",
    title: "Gresie portelanata Gri",
    invoiceId: "invoice-00158",
    price: 12.11
  }
];

const initialState = {
  paymentData: mock_paymentData,
  deliveryData: mock_deliveryData,
  invoicingData: mock_invoicingData,
  type: "GUEST", // GUEST / RESELLER / SMALL / LARGE
  currency: "RON",
  lastOrders: [...mock_lastOrders]
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_TYPE:
      return {
        ...state,
        type: action.payload
      };
    case SET_CLIENT_ADDRESSES:
      return {
        ...state,
        addressList: action.payload,
      };
    default:
      return state;
  }
}

//temp used for testing
export const setClientTypeGlobal = type => {
  return {
    payload: type,
    type: SET_CLIENT_TYPE
  };
};


export const loadClientAddresses = (organizationId) =>
  //TODO: workaround for filter (we need numeric id instead of hashedId)
  apiAction({
    endPoint: "/uniprest/admin/get-id/" + organizationId,
    method: "GET",
    onSuccess: (resp, dispatch) => {
      dispatch(
        apiAction({
          endPoint: "/organization/search-organization-address",
          method: "POST",
          data:{ filters: [{ field: "organizationId", relation: "in", value: resp }] },
          onSuccess: (data, dispatch) => {
            console.log("API data", data);
            dispatch(setApiResponse(data));
            return {
              type: SET_CLIENT_ADDRESSES,
              payload: data,
            };
          },
          onFailure: () => {
            console.log("error occured custom");
            return {
              type: "a",
            };
          },
        })
      );
      return { type: "a" }
    },
    onFailure: (idConverterError) => {
      console.error({ idConverterError })
      return { type: "a" }
    }
  })


