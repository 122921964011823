const SET_IS_ADD = "admin/SET_IS_ADD"
const SET_USERS = "admin/SET_USERS"
const SET_CITIZENS = "admin/SET_CITIZENS"
const SET_CITIZEN_FEEDBACKS = "admin/SET_CITIZEN_FEEDBACKS"
const SET_FORMS = "admin/SET_FORMS"
const SET_ADMINS = "admin/SET_ADMINS"
const SET_SELLERS = "admin/SET_SELLERS"
const SET_ARTICLES = "admin/SET_ARTICLES"
const SET_ROLES = "admin/SET_ROLES"
const SET_COMMUNITIES = "admin/SET_COMMUNITIES"
const SET_PRODUCTS = "admin/SET_PRODUCTS"
const SET_ORDERS = "admin/SET_ORDERS"
const SET_DICTIONARIES = "admin/SET_DICTIONARIES"
const SET_PRODUCT_DETAILS_DICTIONARIES = "admin/SET_PRODUCT_DETAILS_DICTIONARIES"
const SET_LOCATIONS = "admin/SET_LOCATIONS"
const SET_LOCATION_DETAILS = "admin/SET_LOCATION_DETAILS"
const SET_DASHBOARD_DATA = "admin/SET_DASHBOARD_DATA"
const SET_DICTIONARY_DETAILS = "admin/SET_DICTIONARY_DETAILS"
const SET_DICTIONARY_TYPES = "admin/SET_DICTIONARY_TYPES"
const SET_DICTIONARY_CATEGORIES = "admin/SET_DICTIONARY_CATEGORIES"
const SET_ARTICLE_DETAILS = "admin/SET_ARTICLE_DETAILS"

const SET_ACTIVE_CHAT = "admin/SET_ACTIVE_CHAT"
const SET_CHAT_LIST = "admin/SET_CHAT_LIST"

const SET_REGISTER_FARMER_DICTIONARIES = "admin/SET_REGISTER_FARMER_DICTIONARIES"
const SET_ADD_PRODUCT_DICTIONARIES = "admin/SET_ADD_PRODUCT_DICTIONARIES"

const SET_PERMISSIONS_LIST = "admin/SET_PERMISSIONS_LIST";
const SET_PERMISSIONS_NAME = "admin/SET_PERMISSIONS_NAME";
const SET_PERMISSION_TYPES = "admin/SET_PERMISSION_TYPES";

const SET_CITIZENS_ONLINE_APPLICATIONS = "admin/SET_CITIZENS_ONLINE_APPLICATIONS";

const SET_COUNTIES = "admin/SET_COUNTIES";
const SET_LOCALITIES = "admin/SET_LOCALITIES";

const initialState = {
  isAdd: false,
  users: [],
  citizens: [],
  forms: [],
  admins: [],
  sellers: [],
  articles: [],
  roles: [],
  communities: [],
  products: [],
  orders: [],
  dictionaries: [],
  productDictionaries: [],
  locations: [],
  locationDetails: [],
  dashboard: [],
  dictionaryDetails: [],
  dictionayTypes: [],
  dictionaryCategories: [],
  articleDetails: [],
  registerFarmerDictionaries: [],
  addProductDictionaries: [],
  citizenFeedbacks: [],

  activeChat: [],
  chatList: [],

  permissionsList: [],
  permissionsName: [],
  rolePermissions: [],

  citizensOnlineApplications: [],

  counties: [],
  localities: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_ADD:
      return {
        ...state,
        isAdd: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_CITIZENS:
      return {
        ...state,
        citizens: action.payload,
      };
    case SET_CITIZEN_FEEDBACKS:
      return {
        ...state,
        citizenFeedbacks: action.payload,
      };
    case SET_FORMS:
      return {
        ...state,
        forms: action.payload,
      };
    case SET_ADMINS:
      return {
        ...state,
        admins: action.payload,
      };
    case SET_SELLERS:
      return {
        ...state,
        sellers: action.payload,
      };
    case SET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      };
    case SET_CITIZENS_ONLINE_APPLICATIONS:
      return {
        ...state,
        citizensOnlineApplications: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_COMMUNITIES:
      return {
        ...state,
        communities: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_DICTIONARIES:
      return {
        ...state,
        dictionaries: action.payload,
      };
    case SET_PRODUCT_DETAILS_DICTIONARIES:
      return {
        ...state,
        productDictionaries: action.payload,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case SET_COUNTIES:
      return {
        ...state,
        counties: action.payload,
      };
    case SET_LOCALITIES:
      return {
        ...state,
        localities: action.payload,
      };
    case SET_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: action.payload,
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload,
      };
    case SET_DICTIONARY_DETAILS:
      return {
        ...state,
        dictionaryDetails: action.payload,
      };
    case SET_DICTIONARY_TYPES:
      return {
        ...state,
        dictionaryTypes: action.payload,
      };
    case SET_DICTIONARY_CATEGORIES:
      return {
        ...state,
        dictionaryCategories: action.payload,
      };
    case SET_ARTICLE_DETAILS:
      return {
        ...state,
        articleDetails: action.payload,
      };
    case SET_REGISTER_FARMER_DICTIONARIES:
      return {
        ...state,
        registerFarmerDictionaries: action.payload,
      };



    case SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChat: action.payload,
      };

    case SET_CHAT_LIST:
      return {
        ...state,
        chatList: action.payload,
      };

    case SET_ADD_PRODUCT_DICTIONARIES:
      return {
        ...state,
        addProductDictionaries: action.payload,
      };
    case SET_PERMISSIONS_LIST:
      return {
        ...state,
        permissionsList: action.payload,
      };
    case SET_PERMISSIONS_NAME:
      return {
        ...state,
        permissionsName: action.payload,
      };
      case SET_PERMISSION_TYPES:
        return {
          ...state,
          [action.payload.name]: action.payload.perms
        };
    default:
      return state;
  }
}

export const setIsAdd = data => ({
  type: SET_IS_ADD,
  payload: data,
})

export const setUsers = data => ({
  type: SET_USERS,
  payload: data,
})

export const setCitizens = data => ({
  type: SET_CITIZENS,
  payload: data,
})
export const setCitizenFeedbacks = data => ({
  type: SET_CITIZEN_FEEDBACKS,
  payload: data,
})

export const setForms = data => ({
  type: SET_FORMS,
  payload: data,
})

export const setAdmins = data => ({
  type: SET_ADMINS,
  payload: data,
})

export const setSellers = data => ({
  type: SET_SELLERS,
  payload: data,
})

export const setArticles = data => ({
  type: SET_ARTICLES,
  payload: data,
})

export const setCitizensOnlineApplications = data => ({
  type: SET_CITIZENS_ONLINE_APPLICATIONS,
  payload: data,
})

export const setRoles = data => ({
  type: SET_ROLES,
  payload: data,
})

export const setCommunities = data => ({
  type: SET_COMMUNITIES,
  payload: data,
})

export const setProducts = data => ({
  type: SET_PRODUCTS,
  payload: data,
})

export const setOrders = data => ({
  type: SET_ORDERS,
  payload: data,
})

export const setDictionaries = data => ({
  type: SET_DICTIONARIES,
  payload: data,
})

export const setLocations = data => ({
  type: SET_LOCATIONS,
  payload: data,
})

export const setCounties = data => ({
  type: SET_COUNTIES,
  payload: data,
})

export const setLocalities = data => ({
  type: SET_LOCALITIES,
  payload: data,
})

export const setLocationDetails = data => ({
  type: SET_LOCATION_DETAILS,
  payload: data,
})

export const setDashboardData = data => ({
  type: SET_DASHBOARD_DATA,
  payload: data,
})

export const setProductDetailsDictionaries = data => ({
  type: SET_PRODUCT_DETAILS_DICTIONARIES,
  payload: data,
})

export const setDictionaryDetails = data => ({
  type: SET_DICTIONARY_DETAILS,
  payload: data,
})

export const setDictionaryTypes = data => ({
  type: SET_DICTIONARY_TYPES,
  payload: data,
}
)
export const setDictionaryCategories = data => ({
  type: SET_DICTIONARY_CATEGORIES,
  payload: data,
})

export const setArticleDetails = data => ({
  type: SET_ARTICLE_DETAILS,
  payload: data,
})


export const setRegisterFarmerDictionaries = data => ({
  type: SET_REGISTER_FARMER_DICTIONARIES,
  payload: data,
})

export const setAddProductDictionaries = data => ({
  type: SET_ADD_PRODUCT_DICTIONARIES,
  payload: data,
})


// Chats
export const setActiveChat = data => ({
  type: SET_ACTIVE_CHAT,
  payload: data,
})

export const setChatList = data => ({
  type: SET_CHAT_LIST,
  payload: data,
})

export const setPermissionsList = (data) => ({
  type: SET_PERMISSIONS_LIST,
  payload: data,
})

export const setPermissionsName = (data) => ({
  type: SET_PERMISSIONS_NAME,
  payload: data,
})

export const setPermissionsType = (data) => ({
  type: SET_PERMISSION_TYPES,
  payload: data,
})