import { apiAction, reDispatch } from "store/middleware/api";
import { setApiResponse } from "./auth";
import { searchStuff } from "./dataView.search";


const ADD_TO_CART = "shop/ADD_TO_CART"
const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING"
const SET_CART_SEARCH_STRING = "shop/SET_CART_SEARCH_STRING"
export const SET_SEARCH_RESULTS = "shop/SET_SEARCH_RESULTS"
const SET_MENU_SEARCH_STRING = "shop/SET_MENU_SEARCH_STRING"
const TOGGLE_VIEW_TYPE = "shop/TOGGLE_VIEW_TYPE"
const SET_SHOW_PRICES = "shop/SET_SHOW_PRICES"
const SET_LISTS = "shop/SET_LISTS"

const SET_CART_DATA = "shop/SET_CART_DATA";
const CLEAR_CART_DATA = "shop/CLEAR_CART_DATA";


const mock_Product = {
  badgeTitle: "Badge Title",
  brandName: "Brand name",
  price: 130.33,
  fullPrice: "98.285,12 RON (cu TVA)",
  initialPrice: "98.285,12 RON",
  youSave: "-20%",
  title: "This is the product title with a hard limit of 70 (seventy) characters",
  stock: 2,
  stockMessage: "Raspundem in 2 ore",
  rating: 4.3,
  reviews: 120,
  clientType: 'guest',
  priceRange: "288,21 - 323,32",
  quantity : 0,
  quantityUnit: "m2",
  totalPrice: "1.878.355,22 RON",
  
  deals: [
      {
          id: "0001",
          quantity: 10,
          price: "8.355,22 RON",
          discount: "-2%",
          recomanded: false,
      },
      {
          id: "0002",
          quantity: 20,
          price: "78.355,22 RON",
          discount: "-3%",
          recomanded: true,
      },
      {
          id: "0003",
          quantity: 30,
          price: "878.355,22 RON",
          discount: "-5%",
          recomanded: false,
      },
      {
          id: "0004",
          quantity: 40,
          price: "1.878.355,22 RON",
          discount: "-10%",
          recomanded: false,
      }
  ]
}


const initialState = {
  cart : [],
  cartData: {},
  menuSeachString :'',
  cartSearchString: '',
  products: [
    {...mock_Product, id:"PRD-aaaa0001", badgeTitle: null}, 
    {...mock_Product, brandName:"Portelan inc.", stock:100, title: "Gresie portelanata Gri", initialPrice:null, youSave:null, badgeTitle:null, id:"PRD-aaaa0002",}, 
    {...mock_Product, id:"PRD-aaaa0003"}, 
    {...mock_Product, id:"PRD-aaaa0004", quantityUnit: "L", stock:0, youSave:null, badgeTitle:null}, 
    {...mock_Product, id:"PRD-aaaa0005", stock:100, badgeTitle:null}, 
    {...mock_Product, id:"PRD-aaaa0006", stock:1, youSave:null}],
  searchString: '',
  viewType: "cards",
  showPrices: ["wVat", "woVat"],
  lists:[],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
      case SET_CART_DATA:
        return {
          ...state,
          cartData: {...action.payload}
        }
      case CLEAR_CART_DATA:
        return {
          ...state,
          cartData: {}
        }
      case ADD_TO_CART:
          let [cart, products] = addToCartArray(state.cart, state.products, action.payload)
          return {
              ...state,
              cart,
              products,
          };
      case SET_LISTS:
          return {
              ...state,
              lists: action.payload,
          };
      case SET_SEARCH_STRING:
          return {
              ...state,
              searchString: action.payload,
          };
      case SET_CART_SEARCH_STRING:
          return {
              ...state,
              cartSearchString: action.payload,
          };
      case SET_SHOW_PRICES:
          return {
              ...state,
              showPrices: action.payload,
          };
      case SET_SEARCH_RESULTS:
          return {
              ...state,
              menuSearchItems: action.payload?.items,
          };
      case SET_MENU_SEARCH_STRING:
          return {
              ...state,
              menuSeachString: action.payload,
          };
      case TOGGLE_VIEW_TYPE:
          return {
              ...state,
              viewType: state.viewType === "cards" ? "list" : "cards",
          };
      default:
          return state;
  }
}


export const setLists = (payload) => {
  return {
      payload,
      type: SET_LISTS,
  };
}

export const addToCart = (product, quantity) => {
  return {
      payload: {
        ...product,
        quantity,
      },
      type: ADD_TO_CART,
  };
}

export const toggleViewType = () => {
  return {
      type: TOGGLE_VIEW_TYPE,
  };
}
export const setShowPrices = (data) => {
  return {
      type: SET_SHOW_PRICES,
      payload: data,
  };
}


export const setProductsSearchString = (string) => {
  return {
      payload: string,
      type: SET_SEARCH_STRING,
  };
}

export const setCartSearchString = (string) => {
  return {
      payload: string,
      type: SET_CART_SEARCH_STRING,
  };
}


function addToCartArray (cart, listedProducts, product) {
  let isInTheCart = false;
  let newCart = [...cart]
  let newListedProducts = [...listedProducts]
  newCart.forEach((cartObject, index) => {
    if (cartObject.id === product.id) {
      cartObject.quantity = product.quantity;
      isInTheCart = index;
    }
  });
  if (isInTheCart === false) newCart.push(product)
  if (product?.quantity === 0 && isInTheCart !== false) newCart.splice(isInTheCart, 1)

  //after each add we will always refresh the lsitedProducts array
  newListedProducts.forEach((listPrObject) => {    
      if (listPrObject.id === product.id)
        listPrObject.quantity = product.quantity;
    });
  return [newCart, newListedProducts]
}

//we usually just name the variables what they are, WH type
// you can use find to find stuff in arrays
// update the product list by using the new item that caused the modificaition. minus one for loop
// reafactor with arrrow functions



export const setMenuSearchItems = (items) => {
  //make api call with string
  //get result from API 
  //searchDATA = API result
  return {
      payload: {items},
      type: SET_SEARCH_RESULTS,
  };
}
export const clearMenuSearchItems = () => {
  //make api call with string
  //get result from API 
  //searchDATA = API result
  return {
      payload: {items: []},
      type: SET_SEARCH_RESULTS,
  };
}

// export const setMenuSearchString = (searchString) => ({
//       payload: searchString,
//       type: SET_MENU_SEARCH_STRING,
//   })

export const setMenuSearchString = (searchString) => reDispatch(dispatch => {
  //make api call with searchString
  //get result from API 
  //searchDATA = API result
  if (searchString.length > 2) {
    //call api to get results
    // dispatch(setMenuSearchItems())
    dispatch(searchStuff({searchTerm:searchString, target: "menuSearch", reload:true,pageSize:10}))
  } else
    dispatch(clearMenuSearchItems());

  return {
      payload: searchString,
      type: SET_MENU_SEARCH_STRING,
  }
})

export const SetCartData = (cartData) => ({
  payload: cartData,
  type: SET_CART_DATA,
});

export const saveProduct = (data) => apiAction({
  endPoint: `/product`,
  method: "POST",
  data,
  onSuccess: (data, dispatch) => {
    dispatch( setApiResponse(data))
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});

export const modifyBasket = (id, quantity) => apiAction({
  endPoint: `/basket/modify-basket-item`,
  method: "POST",
  data : {
    goodsId: id,
    quantity: quantity,
  },
  onSuccess: (data, dispatch) => {
    dispatch(SetCartData(data));
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error in modifyBasket()");
    return {
      type: "a"
    };
  }
});

export const getCart = (data) => apiAction({
  endPoint: `/basket/my-basket`,
  method: "GET",
  data,
  onSuccess: (data, dispatch) => {
    dispatch(setApiResponse(data));
    dispatch(SetCartData(data));
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});
export const placeOrder = () => { 
  // let state = store.getState();

  return apiAction({
  endPoint: `/basket/finalization`,
  method: "POST",
  // data: state?.shop?.CartData,
  onSuccess: (data, dispatch) => {
    dispatch(setApiResponse(data));
    return {
      type: CLEAR_CART_DATA,
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});
}
