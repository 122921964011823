import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
// import { reducer2 as formReducer } from "react-redux-toastr";
// import { reducer as formReducer } from 'redux-form';
// import { loadingBarReducer } from "react-redux-loading-bar";
import langReducer from "./modules/lang";
import adminReducer from "./modules/admin";
import authReducer from "./modules/auth";
import shopReducer from "./modules/shop";
import citizenReducer from "./modules/citizen";
import jobReducer from "./modules/job";
import guestReducer from "./modules/guest";
import tableReducer from "./modules/table";
import dataViewReducer from "./modules/dataView";
import companyReducer from "./modules/company";
import { addColegiiReducer } from "./modules/colegii";
import formBuilderReducer from "./modules/formBuilder";

// import pageReducer from "./modules/page";
import companyDetailReducer from "./modules/company_details";

import translateReducer from "./modules/translate";
import userReducer from "./modules/user";
import backDataReducer from "./modules/backData";
import dashboardReducer from "./modules/dashboard";
import pageReducer from 'store/modules/page'


// import menuReducer from "./modules/menu";
// import authReducer from "./modules/auth";
import pageTabsReducer from "./modules/pageTabs";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

export const LOGOUT_USER = "LOGOUT_USER";

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('user')
    state = undefined;
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  lang: langReducer,
  admin: adminReducer,
  auth: authReducer,
  shop: shopReducer,
  citizen: citizenReducer,
  page: pageReducer,
  company: companyReducer,
  formBuilder: formBuilderReducer,
  job: jobReducer,
  colegii: addColegiiReducer,
  // form: formReducer,
  toastr: toastrReducer,
  translate: translateReducer,
  user: userReducer,
  table: tableReducer,
  dataView: dataViewReducer,
  guest: guestReducer,
  company_details: companyDetailReducer,
  backData: backDataReducer,
  pageTabs: pageTabsReducer,
  dashboard: dashboardReducer
  // loadingBar: loadingBarReducer,
});

export default rootReducer;
