import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

/**
 * @returns {'sm' | 'md' | 'lg' | 'xl' | '2xl'}
 */
export default function useSize() {
    const { width } = useWindowSize()
    const [size, setSize] = useState("2xl")

    useEffect(() => {
        if (width < 640) setSize("sm")
        else if (width < 768) setSize("md")
        else if (width < 1024) setSize("lg")
        else if (width < 1280) setSize("xl")
        else setSize("2xl")
    }, [width])

    return size
}

