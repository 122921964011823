// import orderTableData from "mockData/orderTableData";
import { reDispatch } from "store/middleware/api";
import store from "../index";
import { setClientTypeGlobal } from "./company";
// import { history } from "utils/history";
// import { deleteDynamoItemByPK } from "../justActions/dynamoOps";
// import { reDispatch } from "store/middleware/api";

export const ADD_ITEMS = "dataView/ADD_ITEMS";
export const SET_ITEMS = "dataView/SET_ITEMS";
export const CLEAR_ITEMS = "dataView/CLEAR_ITEMS";
export const SET_DV_SELECTED_FILTERS = "dataView/SET_DV_SELECTED_FILTERS";
export const RESET_DV = "dataView/RESET_DV";
export const SET_DV_STRING = "dataView/SET_DV_STRING";
export const SET_DV_ITEMS = "dataView/SET_DV_ITEMS";
export const ADD_DV_ITEMS = "dataView/ADD_DV_ITEMS";
export const TOGGLE_SELECT_ITEM = "dataView/TOGGLE_SELECT_ITEM";
export const TOGGLE_SELECT_ALL_ITEMS = "dataView/TOGGLE_SELECT_ALL_ITEMS";
export const ADD_SAVED_FILTER = "dataView/ADD_SAVED_FILTER";
export const DELETE_SAVED_FILTER = "dataView/DELETE_SAVED_FILTER";
export const ACTIVATE_SAVED_FILTER = "dataView/ACTIVATE_SAVED_FILTER";
export const SET_MORE_ACTION_ROW_ID = "dataView/SET_MORE_ACTION_ROW_ID";
export const TOGGLE_SORT = "dataView/TOGGLE_SORT";
export const RESET_ACTIONS = "dataView/RESET_ACTIONS";
export const SET_DV_ID = "dataView/SET_DV_ID";
export const SET_COLUMS = "dataView/SET_COLUMS";

const mockSavedFilters = [
  {
    id: "USF-0000aaaa",
    favorite: true,
    name: "My precious",
    data: {
      "FLT-SMaaaa00": ["2"],
    },
  },
  {
    id: "USF-0000aaab",
    favorite: false,
    name: "My filter",
    data: {
      "FLT-SMaaaa00": ["2"],
      "FLT-RGaaaa00": [0, 530],
    },
  },
];

const initialState = {
  viewId: '',
  items: [],
  selectedItems: [],
  searchString: "",
  columns: null,
  sort: [],
  moreOpenRowId: "",
  // allFilters: [],
  selectedFilters: {
    // 'FLT-sort00p1' : ["ranking|true"],
    // "FLT-sort0prc" : ["both"],
    // "FLT-PCATEG00" : ["#1", "#45"],
    // "FLT-PCATEG01" : ["#1#4", "#45#46"],
  },
  lastItemHint: 0,
  savedFilters: [...mockSavedFilters],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_DV:
      return {
        ...initialState,
      };
    case CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      };
    case SET_COLUMS:
      return {
        ...state,
        columns: action.payload,
      };
    case SET_DV_ID:
      return {
        ...initialState,
        viewId: action.payload,
      };
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload.items,
        lastItemHint: action.payload.lastItemHint,
      };
    case ADD_ITEMS:
      return {
        ...state,
        items: [...(state.items ?? []), ...action.payload.items],
        lastItemHint: action.payload.lastItemHint,
      };
    case SET_MORE_ACTION_ROW_ID:
      return {
        ...state,
        selectedItems: [], //closes more actions modal
        moreOpenRowId: action.payload === state.moreOpenRowId ? "" : action.payload,
      };
    case TOGGLE_SORT:
      return {
        ...state,
        sort: (state?.sort[0] === action.payload[0] && state?.sort[1] === action.payload[1])
          ? []
          : action.payload
      };
    case SET_DV_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case TOGGLE_SELECT_ITEM:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: state.selectedItems.includes(action.payload)
          ? state.selectedItems.filter(item => item != action.payload)
          : [...state.selectedItems, action.payload]
      };
    case RESET_ACTIONS:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: [],
      };
    case TOGGLE_SELECT_ALL_ITEMS:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: state.selectedItems.length === state.items.length
          ? []
          : state.items.map(item => (item?.id ?? item?.PK))
      };
    case SET_DV_ITEMS:
      return {
        ...state,
        rows: action.payload.rows,
      };
    case ADD_DV_ITEMS:
      return {
        ...state,
        rows: [...state.rows, ...action.payload.rows],
        lastRowHint: action.payload.lastRowHint,
      };
    case ADD_SAVED_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters, action.payload],
      };
    case ACTIVATE_SAVED_FILTER:
      return {
        ...state,
        selectedFilters: state.savedFilters.find((e) => e.id === action.payload).data,
      };
    case DELETE_SAVED_FILTER:
      return {
        ...state,
        savedFilters: state?.savedFilters && state.savedFilters.filter((item) => item.id !== action.payload),
      };
    case SET_DV_SELECTED_FILTERS:
      // let prev = state.selectedFilters
      return {
        ...state,
        selectedFilters: action.payload?.id
          ? {
            ...state.selectedFilters,
            [action.payload.id]: action.payload.selection,
          }
          : undefined,
      };
    default:
      return state;
  }
}

export const setColumns = (data) => {
  return {
    type: SET_COLUMS,
    payload: data,
  };
};
export const setDataViewId = (id) => {
  return {
    type: SET_DV_ID,
    payload: id,
  };
};
export const setDataItems = (items, lastItemHint) => {

  return {
    type: SET_ITEMS,
    payload: {
      items, lastItemHint,
    },
  };
};
export const addDataItems = (items, lastItemHint) => {

  return {
    type: ADD_ITEMS,
    payload: {
      items, lastItemHint,
    },
  };
};

export const addSavedFilter = (name) => {
  let state = store.getState();
  return {
    type: ADD_SAVED_FILTER,
    payload: {
      id: "USF-0000a" + ((Math.random() * 1000) % 1000),
      favorite: false,
      name,
      data: state.dataView.selectedFilters,
    },
  };
};


export const toggleSelectedItem = (id) => {
  return {
    type: TOGGLE_SELECT_ITEM,
    payload: id,
  };
};

export const toggleSort = (payload) => {
  return {
    type: TOGGLE_SORT,
    payload,
  };
};

export const setMoreOpenRowId = (id) => {
  return {
    type: SET_MORE_ACTION_ROW_ID,
    payload: id,
  };
};
export const toggleSelectAllItems = () => {
  return {
    type: TOGGLE_SELECT_ALL_ITEMS,
  };
};

export const deleteSavedFilter = (id) => {
  return {
    type: DELETE_SAVED_FILTER,
    payload: id,
  };
};

export const activateSavedFilter = (id) => {
  return {
    type: ACTIVATE_SAVED_FILTER,
    payload: id,
  };
};

export const resetDV = () => {
  return {
    type: RESET_DV,
  };
};

export const setDVsearchString = (string) => {
  return {
    payload: string,
    type: SET_DV_STRING,
  };
};

export const setDVitems = (items) => {
  return {
    payload: { items },
    type: SET_DV_ITEMS,
  };
};

export const addDVitems = (items) => {
  return {
    payload: { items },
    type: ADD_DV_ITEMS,
  };
};

export const setFilterSelection = (id, selection) => reDispatch(dispatch => {
  if (id === "FLT-tipClient") dispatch(setClientTypeGlobal(selection?.[0]))
  return {
    payload: { id, selection },
    type: SET_DV_SELECTED_FILTERS,
  };
})


// FLT-tipClient