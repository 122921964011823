import { useEffect, useRef, useState } from "react"

export default function useShowModalOutsideClick(props){
  const [showModal, setShowModal] = useState(false)
  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false)
      }
      if (props?.handleOutsideClick && showModal && ref.current && !ref.current.contains(event.target)) {
        props.handleOutsideClick()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, showModal]);

  return {ref, showModal, setShowModal}
}