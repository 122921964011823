import { createSelector } from 'reselect'
import { uni_location } from 'staticData/dynamoTypes'
// import { mockInvoices } from "mockData/invoices";

// const language = (state) => state.user?.language;
// const customAPIoptions = (state) => state?.options?.myspecialOptions;

const ddOptions = (state) => state?.backData?.ddOptions
const locationOptions = (state) => state?.backData?.dictionary?.LOCATION_RESPONSE
const avaliableJobsOptions = (state) => state?.backData?.dictionary?.JOB_RESPONSE
const uni_locationOptions = (state) => state?.backData?.dictionary?.[uni_location]
const backData = (state) => state?.backData

const orderProcessMethod = [
  { value: 'AUTOMATIC', label: 'Procesare automata' },
  { value: 'MANUAL', label: 'Procesare manuala' },

]
const mockOptions1 = [
  { value: 'ID1', label: 'Option 1' },
  { value: 'ID2', label: 'Option 2' },
  { value: 'ID3', label: 'Option 3' },
  { value: 'ID4', label: 'Option 4' }
]
const mockOptions2 = [
  { value: 'ID1', label: 'Option 21' },
  { value: 'ID2', label: 'Option 22' },
  { value: 'ID3', label: 'Option 23' },
  { value: 'ID4', label: 'Option 24' }
]
const mockOptionsId = [
  { id: 'ID1', name: 'Option 21' },
  { id: 'ID2', name: 'Option 22' },
  { id: 'ID3', name: 'Option 23' },
  { id: 'ID4', name: 'Option 24' }
]

const experienceOptions = [
  { value: 1, label: ' Intership' },
  { value: 2, label: 'Junior' },
  { value: 3, label: 'Engineer' }
]

const jobAreaOptions = [
  { value: 1, label: 'Logistica si livrare la Clienti' },
  { value: 2, label: 'HR' },
  { value: 3, label: 'Marketing' },
  { value: 4, label: 'Administration' }
]

const contactPurposeOptions = [
  { id: 'ID1', label: 'Raportez o problema cu o comanda' },
  { id: 'ID2', label: 'HR' },
  { id: 'ID3', label: 'Marketing' },
  { id: 'ID4', label: 'Administration' }
]

const docForm = {
  fieldName: [
    { id: 1, label: 'Yes' },
    { id: 0, label: 'No' }
  ]
}

const yesNo = [
  { id: 1, label: 'Yes' },
  { id: 0, label: 'No' }
]
const nuDa = [
  { id: "1", label: 'DA' },
  { id: "0", label: 'NU' }
]

const status = [
  { id: 1, label: 'Arhivat' },
  { id: 0, label: 'Nearhivat' }
]

const generalOptions = {
  defualt: mockOptions1,
  ddOnex: mockOptions1,
  yesNo,
  status,
  orderProcessMethod
}

const testForm = {
  select: mockOptions1,
  ddOne: mockOptions2,
  ddMulti: mockOptions1,
  simpleList: mockOptionsId,
  experienta: experienceOptions,
  ariaDeLucru: jobAreaOptions,
  contactPurpose: contactPurposeOptions
}

const productPackForm = {
  pachetInformation: [
    {
      pachetName: 'Numele pachet',
      stocmax: 300,
      stocactual: 1120,
      numberOfSales: 380,
      generatedIncome: 2.8,
      inititalDate: '31.August.10',
      expirationDate: '31.December.10',
      remainingDays: 25,
      productPrice: '11.200 - 15.800',
      companiiNumbers: 245
    }
  ]
}

const companyForm = {
  numeScurt: {
    label: 'Nume scurt',
    placeholder: 'Nume companie '
  },
  numeJuridica: {
    label: 'Nume entitate juridica',
    placeholder: 'Numele companiei'
  },
  cui: {
    label: 'CUI',
    placeholder: 'RO 1234568'
  },
  onrc: {
    label: 'ONRC',
    placeholder: 'J40/21/12.03.2021'
  },
  sediul: {
    label: 'Sediul social',
    placeholder: 'Adresa sediului'
  },
  administrator: {
    label: '',
    placeholder: 'Andrei Popescu'
  },
  email: {
    label: 'Unde iti putem scrie?',
    placeholder: 'Email'
  },
  telefon: {
    label: 'Unde te putem suna?',
    placeholder: '0742111222'
  },
  factura: {
    label: 'Factura la termen (din limita de credit)'
    //placeholder: "factura"
  },
  transfer: {
    label: 'Plata prin transfer bancar'
    //placeholder: "transfer"
  },
  card: {
    label: 'Plata cu card bancar'
    //placeholder: "card"
  },
  bilet: {
    label: 'Bilet la ordin / CEC la livrare marfa'
    //placeholder: "card"
  },
  relationManagers: [
    { id: 1, rol: 'Manager de Cont', firstName: 'Ion', lastName: 'Ionescu' },
    {
      id: 2,
      rol: 'manager de vanzari',
      firstName: 'Adrian',
      lastName: 'Percub'
    },
    { id: 3, rol: 'Manager de Cont', firstName: 'Vlad', lastName: 'Cotaga' },
    {
      id: 4,
      rol: 'Manager de Vanzari',
      firstName: 'Razvan',
      lastName: 'Popescu'
    }
  ]
}

const adreseForm = {
  adresa: {
    label: 'Adresa*',
    placeholder: 'Adresa* '
  },
  relationManagers: [
    {
      id: 1,
      role: 'Suport technic',
      firstName: 'Andrei',
      lastName: 'Popescu',
      telNumber: '+40 875 855 510'
    },
    {
      id: 2,
      role: 'Suport technic',
      firstName: 'Adrian',
      lastName: 'Percub',
      telNumber: '+40 875 855 510'
    },
    {
      id: 3,
      role: 'Suport technic',
      firstName: 'Andrei',
      lastName: 'Popescu',
      telNumber: '+40 875 855 510'
    },
    {
      id: 4,
      role: 'Suport technic',
      firstName: 'Razvan',
      lastName: 'Popescu',
      telNumber: '+40 875 855 510'
    }
  ],
  phoneNumebrs: [
    { id: 1, telNumber: '+40 875 855 510', type: 'Telefon general' },
    {
      id: 2,

      telNumber: '+40 875 855 510'
    },
    { id: 3, telNumber: '+40 875 855 510', type: 'Telefon general' },
    {
      id: 4,

      telNumber: '+40 875 855 510',
      type: 'Livrare de comenzi'
    }
  ]
  // adressList: [
  //   {
  //     id: 1,
  //     title: "Adress title 1",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  //   {
  //     id: 2,
  //     title: "Adress title 2",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  //   {
  //     id: 3,
  //     title: "Adress title 3",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  // ],

}

const mockUserPosition = [
  {id: "07", label:	"Manager audit",},
  {id: "08", label:	"Auditor",},
  {id: "09", label:	"Inspector bancar",},
]

const mockWorkplace = [
  {
    "workplaceId": "VrXRlL",
    "workplaceName": "Iasi",
    "country": "Romania",
    "county": "Iași",
    "locationName": "Iasi",
    "contactInfo": "{\"email\":\"iasi@uniprest.ro\",\"mobil\":null,\"phone\":\"0365-882.077\"}"
},
{
    "workplaceId": "Vle3rK",
    "workplaceName": "Cluj",
    "country": "Romania",
    "county": "Cluj",
    "locationName": "Cluj Napoca",
    "contactInfo": "{\"email\":\"facturare.cluj@uniprest.ro\",\"mobil\":null,\"phone\":\"0364-882.301\"}"
},
{
    "workplaceId": "XlDAbm",
    "workplaceName": "Sediu central",
    "country": "Romania",
    "county": "Mureș",
    "locationName": "Targu Mures",
    "contactInfo": null
},
{
    "workplaceId": "wlp2rz",
    "workplaceName": "Mures",
    "country": "Romania",
    "county": "Mureș",
    "locationName": "Targu Mures",
    "contactInfo": "{\"email\":\"tgmures@uniprest.ro\",\"mobil\":null,\"phone\":\"0365-430.912\"}"
},
{
    "workplaceId": "olWErN",
    "workplaceName": "Brasov",
    "country": "Romania",
    "county": "Brașov",
    "locationName": "Brasov",
    "contactInfo": "{\"email\":\"brasov@uniprest.ro\",\"mobil\":null,\"phone\":\"0368-460.199\"}"
},
{
    "workplaceId": "NmkQZE",
    "workplaceName": "Sibiu",
    "country": "Romania",
    "county": "Sibiu",
    "locationName": "Sibiu",
    "contactInfo": "{\"email\":\"sibiu@uniprest.ro\",\"mobil\":null,\"phone\":\"0369-437.936\"}"
},
{
    "workplaceId": "Lmnqln",
    "workplaceName": "Depozit central",
    "country": "Romania",
    "county": "Mureș",
    "locationName": "Targu Mures",
    "contactInfo": "{\"email\":\"zsolt.kovacs@uniprest.ro\",\"mobil\":null,\"phone\":\"0365-882.077\"}"
}
]

export const selectFormOptions = createSelector(ddOptions, locationOptions, avaliableJobsOptions, uni_locationOptions, backData,
  (ddOptions, locationOptions, avaliableJobsOptions, uni_locationOptions, backData) => {
    const javaDictToOptions = (dict) => backData?.dictionary?.[dict]?.map((item) => ({
      id: item?.value,
      label: item?.label
    }))
    const javaUniLocationsToOptions = () => backData?.dictionary?.["ALL_UNI_LOCATIONS"]?.map((item) => ({
      id: item?.workplaceId,
      label: item?.workplaceName
    }))
    const javaRolesToOptions = (external) => backData?.dictionary?.["ALL_ROLES"]
      ?.filter(e => external ? ["grK9lA", "WmGwm7"].includes(e.value) : ! ["grK9lA", "WmGwm7"].includes(e.value)) //these are the external group ids
      ?.map((item) => ({
      id: item?.value,
      label: item?.label
    }))
    const topProductCategory = backData?.productCategory?.map((item) => ({
      id: item?.id,
      label: item?.name
    }))
    const allUsers = backData?.list?.["uniprest/user"]?.map((item) => ({
      id: item?.id,
      label: item?.name
    }))
    return {
      allOptions: {
        general: {...generalOptions,
          uni_location: uni_locationOptions?.map((item) => ({
            value: item?.PK,
            label: item?.values?.nume
          })),
          uni_location_mock: mockWorkplace?.map((item) => ({
            id: item?.workplaceId,
            value: item?.workplaceId,
            label: item?.workplaceName
          })),
          brandName: javaDictToOptions("PRODUCT_BRAND"),
          productSupplier: javaDictToOptions("PRODUCT_SUPPLIER"),
          groupIdsExternal: javaRolesToOptions(true),
          groupIdsInternal: javaRolesToOptions(false),
          workplaceId: javaUniLocationsToOptions(),
          //reasonOfBanning:
          position : mockUserPosition,
          positionPublic : mockUserPosition,
          productCategory : topProductCategory,
          responsible: javaDictToOptions("USER_LIST"),
          clientGroup: javaDictToOptions("PARTNER_GROUP")
          // position : javaDictToOptions("USER_POSITION"),
        },
        generalOptions: { ...generalOptions, ddOptions},
        testForm: {
          ...testForm,
          avaliableJobs: avaliableJobsOptions?.map((item) => ({
            value: item?.PK,
            // value: item?.values?.avaliableJobs,
            label: item?.values?.content?.[0]?.title
          })),
          punctulDeLucru: locationOptions?.map((item) => ({
            value: item?.PK,
            label: item?.values?.nume
          })),

        },
        docForm,
        companyForm,
        adreseForm,
        productPackForm,
        productForm: {
          supplierName: javaDictToOptions("PRODUCT_SUPPLIER"),
        }
      }
    }
  }
)
