import { BrowserRouter as Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import routes, { renderRoutes } from "routes/routes"
import "./App.css"
import React, { useEffect } from "react"
import ThemeContext from "./theme/themeProvider"
import { theme } from "./theme/theme"
import ReduxToastr from "react-redux-toastr"
import AWS from "aws-sdk"
import { Amplify } from "aws-amplify"
import { useSelector } from "react-redux"
import { selectFormErros } from "store/selectors/form/formErrors.selector"
import "style/index.scss"
import { useDispatch } from "store"
import { setPageEdit } from "store/modules/page"
import ConfirmActionModal from "componentsCmbr/modals/confirmationActionModal"
import "moment/locale/ro"
import "moment/locale/hu"

import "./localization/config"
import { useTranslation } from "react-i18next"

import 'utils/yupCNPvalidation'

const history = createBrowserHistory({ forceRefresh: true })

AWS.config.region = "eu-central-1" // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
})

// Called when an identity provider has a token for a logged in user
export function userLoggedIn(providerName, token) {
  const creds = AWS.config.credentials
  creds.params.Logins = creds.params.Logins || {}
  creds.params.Logins[providerName] = token
  //console.log(creds.params.Logins)
  // Expire credentials to refresh them on the next request
  creds.expired = true
}

export function userLogOut() {
  const creds = AWS.config.credentials
  creds.params.Logins = {}
  creds.expired = true
}

Amplify.configure({
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_USER_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID
  }
})


function App() {
  const { i18n } = useTranslation();

  const { yupLocale } = useSelector(state => selectFormErros(state))
  const authenticatedState = useSelector(state => state.auth?.authenticated)
  const lang = useSelector(state => state.lang?.lang)
  const dispatch = useDispatch() //temp auto login
  //useEffect(() => { yup.setLocale(yupLocale); }, [yupLocale])

  // useEffect(() => {
  //   // if (authenticatedState === undefined || authenticatedState === false ) dispatch(javaLogin("anonymous", "Password123!!!!", true)) //anonymus login

  //   if (authenticatedState === undefined || authenticatedState === false ) dispatch(login({username:'test14@test.com',pin:'1234'})) //anonymus login

  // }
  // ,[])

  useEffect(() => {
    document?.documentElement?.setAttribute("lang", lang.toLowerCase())
    i18n.changeLanguage(lang);
  }, [lang])

  const url = window.location.pathname
  useEffect(() => {
    if (url.includes("form")) dispatch(setPageEdit(false))
  }, [url])

  const basenamePrefix = process.env.REACT_APP_BASENAME_PREFIX

  return (
    <ThemeContext.Provider value={theme}>
      <Router basename={basenamePrefix} history={history}>
        {renderRoutes(routes)}
        <ReduxToastr
          timeOut={10000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-left"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          // progressBar
          closeOnToastrClick />
        <ConfirmActionModal />


      </Router>
    </ThemeContext.Provider>
  )
}

export default App
