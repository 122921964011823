const SET_CATEGORY = "formBuilder/SET_CATEGORY"
const SET_DEPARTMENT = "formBuilder/SET_DEPARTMENT"
const SET_TYPE = "formBuilder/SET_TYPE"
const SET_ELEMENTS = "formBuilder/SET_ELEMENTS"
const SET_INIITIAL_ELEMENTS = "formBuilder/SET_INIITIAL_ELEMENTS"
const SET_DOCS = "formBuilder/SET_DOCS"
const SET_FORM_DATA = "formBuilder/SET_FORM_DATA"
const SET_CHECKED_FORM_NAME = "formBuilder/SET_CHECKED_FORM_NAME"

const initialState = {
  formCategories: [],
  formDepartments: [],
  formTypes: [],
  formElements: [],
  initialFormElements: [],
  formDocs: [],
  formData: {},
  checkedFormName: undefined,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        ...state,
        formCategories: action.payload,
      };
    case SET_DEPARTMENT:
      return {
        ...state,
        formDepartments: action.payload,
      };
    case SET_TYPE:
      return {
        ...state,
        formTypes: action.payload,
      };
    case SET_ELEMENTS:
      return {
        ...state,
        formElements: action.payload,
      };
    case SET_INIITIAL_ELEMENTS:
      return {
        ...state,
        initialFormElements: action.payload,
      };
    case SET_DOCS:
      return {
        ...state,
        formDocs: action.payload,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case SET_CHECKED_FORM_NAME:
      return {
        ...state,
        checkedFormName: action.payload,
      };
    default:
      return state;
  }
}

export const setFormCategories = data => ({
  type: SET_CATEGORY,
  payload: data,
})

export const setFormDepartments = data => ({
  type: SET_DEPARTMENT,
  payload: data,
})

export const setFormTypes = data => ({
  type: SET_TYPE,
  payload: data,
})

export const setFormElements = data => ({
  type: SET_ELEMENTS,
  payload: data,
})

export const setInitialFormElements = data => ({
  type: SET_INIITIAL_ELEMENTS,
  payload: data,
})

export const setFormDocs = data => ({
  type: SET_DOCS,
  payload: data,
})

export const setFormData = data => ({
  type: SET_FORM_DATA,
  payload: data,
})

export const setCheckedFormName = data => ({
  type: SET_CHECKED_FORM_NAME,
  payload: data,
})