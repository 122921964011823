import { apiAction, dynamoAction } from 'store/middleware/api';
import { getSKfromType } from 'utils/geenrateIds';
import store from '../index'
import { setApiResponse } from './auth';
import { SET_DICTIONARY } from './backData';
import { ADD_ITEMS, SET_ITEMS } from './dataView';
import { SET_CRT_FORM_ITEM } from './page';
import { SET_SEARCH_RESULTS } from './shop';

//TODO: move to a normal place :)
// *** Search operators
// EQUAL("=="),
// NOT_EQUAL("!="),
// LESS("<"),
// LESS_OR_EQUAL("<="),
// GREATER(">"),
// GREATER_OR_EQUAL(">="),
// BETWEEN("between"),
// NOT_BETWEEN("!between"),
// LIKE("~"),
// NOT_LIKE("!~"),
// STARTS_WITH("starts_with"),
// ENDS_WITH("ends_with"),
// EMPTY("empty"),
// NOT_EMPTY("!empty"),
// SUBQUERY_IN("subquery_in"),
// NOT_SUBQUERY_IN("!subquery_in"),
// IN("in"),
// NOT_IN("!in"),

// OR("or"),
// AND("and");

const productSearchData = {
  "firstRow": 1000,
  "pageSize": 10,
  "sortField": null,
  "sortAsc": false,
  "filters": [],
  "filterText": "",
  "searchTerm": null,
  "jsonLogicText": null,
  "jsonLogic": {
    "operation": "OR",
    "children": [
    ],
    "field": null,
    "value": null
  }
}

  // selectedFilters: {
  //   'FLT-SMaaaa00': [ '2', '3']
  // }


const filterKeys = {
  "FLT-SMaaaa00": 'brandName',
  "FLT-SMaaaa11": 'brandName',
  "FLT-GeneralStatus01": 'status',
  "FLT-PersPosition": 'position',
}

//Hint: this is a workaround solution, until API will have only one type of search (at least as request inputs)
const createClassicFilters = () => {
  let state = store.getState();
  let result = []
  const selectedFilters = state?.dataView?.selectedFilters
  
  //next section is used by the brandName >> version 1 for general multiselect...
  for (const filterId in selectedFilters) {
    const filterValues = selectedFilters?.[filterId]

    if (filterValues?.length > 0 && ['FLT-SMaaaa11','FLT-SMaaaa00','FLT-GeneralStatus01', 'FLT-PersPosition'].includes(filterId)) {
      result.push({
        "beginParenthesis": "(",
        "field": filterKeys?.[filterId],
        "relation": "in",
        "value": filterValues?.join(','),
        "operator": "AND",
        "endParenthesis": ")",
      })
    }

    // "filters": [
    //   {"field": "status", "relation": "in", "value": "ACTIVE"},
    //   {"beginParenthesis": "(", "field": "name", "relation": "~", "value": "denes", "operator": "OR"},
    //   {"field": "name", "relation": "~", "value": "anon", "endParenthesis": ")"}
    // ],

  }

  return result
}
const processGuestSort = () => {
  let state = store.getState();
  const selectedFilters = state?.dataView?.selectedFilters
  const guestSortFilter = selectedFilters?.["FLT-sort00p1"]?.[0]
  let [sortField, sortAsc] = guestSortFilter?.split('|') ?? [null, null]
  sortAsc = Boolean(sortAsc === "true")
  return  [sortField, sortAsc]
}
const createFilters = () => {
  let state = store.getState();
  let result = []
  const selectedFilters = state?.dataView?.selectedFilters
  //next section is used by the brandName >> version 1 for general multiselect...
  for (const filterId in selectedFilters) {
    const filterValues = selectedFilters?.[filterId]
    if (filterValues?.length > 0 && (filterId === 'FLT-SMaaaa11' || filterId === 'FLT-SMaaaa00')) {
      result.push({ //temp disable untill we get the logic working fine
        "operation": "IN",
        "children": [
          {
            "operation": null,
            "children": null,
            "field": filterKeys?.[filterId],
            "value": filterValues?.join(','),
          }
        ],
        "field": null,
        "value": null
      })
    }

    if (filterValues?.length > 0 && filterId === 'FLT-GeneralStatus01') {
      result.push({ //temp disable untill we get the logic working fine
        "operation": "IN",
        "children": null,
        "field": filterKeys?.[filterId],
        "value": filterValues?.join(','),
      })
    }
  }

  //next section looks for category filter.
  //work in progress
  // const treeCode = selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG00"]
  // const treeCode = undefined
  const treeCode = selectedFilters?.["FLT-CATEG001"]?.[0]
  if (treeCode) result.push({
    "operation": "STARTS_WITH",
    "children": [
      {
        "operation": null,
        "children": null,
        "field": "categoryTreeCode",
        "value": treeCode,
      }
    ],
    "field": null,
    "value": null
  })

  return result
}

export const searchStuff = ({ type = "product", sortAsc = true, sortField, firstRow = 0, pageSize = 25, searchTerm, reload, target, dictionary }) => {
  let state = store.getState();
  const nextAction = target === "menuSearch"
    ? SET_SEARCH_RESULTS
    : reload ? SET_ITEMS : ADD_ITEMS
  const [guestSortField, guestSortAsc] = processGuestSort()
  return apiAction({
    endPoint: `/${type}/search`,
    method: "POST",
    data: {
      ...productSearchData,
      sortAsc: state?.dataView?.sort?.[1] ?? guestSortAsc ?? sortAsc,
      sortField: state?.dataView?.sort?.[0] ?? guestSortField ?? sortField,
      firstRow: reload 
        ? 0 
        : state?.dataView?.lastItemHint 
          ? state?.dataView?.lastItemHint
          : 0,
      pageSize,
      searchTerm: searchTerm ?? state.dataView?.searchString,
      filters: createClassicFilters(),
      jsonLogic: {
        "operation": "AND",
        "children": createFilters(),
        "field": null,
        "value": null
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const newLastItemHint = Number(reload ? 0 : state?.dataView?.lastItemHint ?? 0) + data.length
      if (dictionary)
      {
        let payload = {}
        payload[ dictionary ] = data
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else
        return {
          type: nextAction,
          payload: {
            items: data,
            lastItemHint: data.length === pageSize && newLastItemHint,
          },
        };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a"
      };
    }
  });
}


export const loadDynamoItemsBySK = ({ SK, reload = true, dictionary }) =>
  dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: "TempDevStack-MainTable-74SE74RNUI3D",
      KeyConditionExpression: "#pk = :pkvalue",
      IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "SK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: SK }
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))

      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else
        return {
          type: reload ? SET_ITEMS : ADD_ITEMS,
          payload: {
            items: data?.items,
            lastItemHint: data?.lastEvalKey
          }
        }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: "a"
      }
    }
  })

  
export const loadDynamoFormItemByPK = ({ PK, reload = true, dictionary }) =>
  dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: "TempDevStack-MainTable-74SE74RNUI3D",
      KeyConditionExpression: "#pk = :pkvalue",
      // IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "PK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: PK }
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const firstItem = data?.items?.[ 0 ]
      
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else
        return {
          type: SET_CRT_FORM_ITEM,
          payload: {
            data: {
              ...(firstItem?.values ?? {}),
              PK: firstItem?.PK, //used to identify and opened Dynamo item
              SK: firstItem?.SK
            }
          }
        }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: "a"
      }
    }
  })



  export const loadDynamoFormItemByPK_v2 = ({ input, reload = true, dictionary }) =>{
    const PK = input.split?.('|')?.[0]
    const SK = input.split?.('|')?.[1] ?? getSKfromType(PK.split?.('-')?.[0])
    
    return dynamoAction({
      method: "QUERY",
      parameters: {
        TableName: "TempDevStack-MainTable-74SE74RNUI3D",
        KeyConditionExpression: "#pk = :pkvalue and sk = :skvalue",
        // IndexName: "SK-PK",
        ExpressionAttributeNames: {
          "#pk": "PK",
          "#sk": "SK",
        },
        ExpressionAttributeValues: {
          ":pkvalue": { S: PK },
          ":skvalue": { S: SK },
        }
      },
      onSuccess: (data, dispatch) => {
        dispatch(setApiResponse(data))
        const firstItem = data?.items?.[ 0 ]
        
        if (dictionary) {
          let payload = {}
          payload[dictionary] = data?.items
          return {
            type: SET_DICTIONARY,
            payload
          }
        } else
          return {
            type: SET_CRT_FORM_ITEM,
            payload: {
              data: {
                ...(firstItem?.values ?? {}),
                PK: firstItem?.PK, //used to identify and opened Dynamo item
                SK: firstItem?.SK
              }
            }
          }
      },
      onFailure: (err) => {
        // showMagicToaster({title: err})
        console.log(err)
        return {
          type: "a"
        }
      }
    })
  }
  