export const SideMenuItems = [
    {
        name: "menu.dashboard",
        svg: "sepsidashboard",
        url: "/web-admin",
        permission: "any"
    },
    {
        name: "menu.newsList",
        svg: "sepsilist",
        url: "/web-admin/news-list",
        permission: "article"
    },
    {
        name: "menu.representativesList",
        svg: "businessblack",
        url: "/web-admin/representatives-list",
        permission: "citizen",
        style: {stroke:'none', fill: '#333'}
    },
    {
        name: "menu.citizenList",
        svg: "sepsipeople",
        url: "/web-admin/citizen/citizen-list",
        permission: "citizen"
    },
    {
        name: "menu.enrollmentList",
        svg: "sepsipersonadd",
        url: "/web-admin/enrolment-request-list",
        permission: "citizen_applications"
    },
    {
        name: "menu.formList",
        svg: "sepsifilecopy",
        url: "/web-admin/form-list",
        permission: "online_forms"
    },
    {
        name: "menu.citizensOnlineApplications",
        svg: "sepsiviewlist",
        url: "/web-admin/citizens-online-applications",
        permission: "online_form_applications"
    },
    {
        name: "menu.ratingList",
        svg: "sepsifeedback",
        url: "/web-admin/rating-list",
        permission: "citizen_feedbacks"
    },
    {
        name: "menu.userList",
        svg: "sepsiverifieduser",
        url: "/web-admin/user/user-list",
        permission: "admin"
    },
    {
        name: "menu.profile",
        svg: "sepsiperson",
        url: "/web-admin/profile",
        permission: "any"
    },
    {
        name: "menu.roleList",
        svg: "sepsipersonadddisabled",
        url: "/web-admin/users/role-list",
        permission: "user_roles"
    },

    //CitizenMenu
    {
        name: "menu.citizenDashboard",
        svg: "sepsidashboard",
        url: "/dashboard"
    },
    {
        name: "menu.onlineForms",
        svg: "sepsiwifitethering",
        url: "/dashboard/online-forms"
    },
    {
        name: "menu.onlineApplications",
        svg: "sepsiviewlist",
        url: "/dashboard/online-applications"
    },
    {
        name: "menu.citizenProfile",
        svg: "sepsiperson",
        url: "/dashboard/profile"
    }
]