export const SET_ONLINE_FORM_DATA = "citizen/SET_ONLINE_FORM_DATA";
export const SET_APPLICATION_ID = "citizen/SET_APPLICATION_ID";

const initialState = {
  onlineFormData: undefined,
  application_id: undefined
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ONLINE_FORM_DATA:
      return {
        ...state,
        onlineFormData: action.payload,
      };
    case SET_APPLICATION_ID:
      return {
        ...state,
        application_id: action.payload,
      };
    default:
      return state;
  }
}

export const setCitizenOnlineFormData = (data) => ({
  type: SET_ONLINE_FORM_DATA,
  payload: data
})

export const setCitizenApplicationId = (data) => ({
  type: SET_APPLICATION_ID,
  payload: data
})