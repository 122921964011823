import { dynamoAction } from 'store/middleware/api'
import geenrateId from 'utils/geenrateIds'
import { showMagicToaster } from 'utils/MagicToasterCall'
import moment from 'moment'
import AWS from 'aws-sdk'
import { setPageEdit } from './page'
// import { cancelEdit } from "utils/useMagicForm"

const SET_ARTICLES = 'guest/SET_ARTICLES'
const SET_ARTICLES_SS = 'guest/SET_ARTICLES_SS'
const SET_UNISERVICES = 'guest/SET_UNISERVICES'
const SET_PARTNERS = 'guest/SET_PARTNERS'
const TOGGLE_LEFT_MENU_VISIBILITY = 'guest/TOGGLE_LEFT_MENU_VISIBILITY'
const SET_JOBS = 'guest/SET_JOBS'
const SET_SELECT_DATA = 'guest/SET_DD_DATA'
// const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING"

const initialState = {
  articles: [],
  services: [],
  partners: [],
  jobs: [],
  selectData: [],
  articlesSearchString: '',
  showLeftMenu: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LEFT_MENU_VISIBILITY:
      return {
        ...state,
        showLeftMenu: action.payload ?? !state.showLeftMenu
      }
    case SET_ARTICLES:
      return {
        ...state,
        articles: action.payload
      }
    case SET_ARTICLES_SS:
      return {
        ...state,
        articlesSearchString: action.payload
      }
    case SET_UNISERVICES:
      return {
        ...state,
        services: action.payload
      }
    case SET_PARTNERS:
      return {
        ...state,
        partners: action.payload
      }
    case SET_JOBS:
      return {
        ...state,
        jobs: action.payload
      }
    case SET_SELECT_DATA:
      return {
        ...state,
        selectData: action.payload
      }
    default:
      return state
  }
}

export const toggleLeftMenuVisibility = (value) => {
  return {
    type: TOGGLE_LEFT_MENU_VISIBILITY,
    payload: value
  }
}

export const setArticlesSearchString = (string) => {
  return {
    payload: string,
    type: SET_ARTICLES_SS
  }
}

export const loadArticles = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      ExpressionAttributeNames: {
        '#pk': 'PK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'ARTICLE' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_ARTICLES,
        lastEvalKey: data.lastEvalKey
      }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const loadServices = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      IndexName: 'SK-PK',
      ExpressionAttributeNames: {
        '#pk': 'SK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'UNISERVICE' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_UNISERVICES,
        lastEvalKey: data.lastEvalKey
      }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const loadPartners = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      IndexName: 'SK-PK',
      ExpressionAttributeNames: {
        '#pk': 'SK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'UNIPARNER' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_PARTNERS,
        lastEvalKey: data.lastEvalKey
      }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const loadJobs = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      IndexName: 'SK-PK',
      ExpressionAttributeNames: {
        '#pk': 'SK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'UNIJOB' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_JOBS
      }
    },
    onFailure: (err) => {
      showMagicToaster({ title: 'A aparut o eroare la accesarea joburilor!' })
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const loadSelectData = (PK) =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      ExpressionAttributeNames: {
        '#pk': 'PK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: PK }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_SELECT_DATA
      }
    },
    onFailure: (err) => {
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const loadMapLocations = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      IndexName: 'SK-PK',
      ExpressionAttributeNames: {
        '#pk': 'SK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'MAPLOCATION' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_PARTNERS,
        lastEvalKey: data.lastEvalKey
      }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const testAWS = () =>
  dynamoAction({
    method: 'QUERY',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      KeyConditionExpression: '#pk = :pkvalue',
      ExpressionAttributeNames: {
        '#pk': 'PK'
      },
      ExpressionAttributeValues: {
        ':pkvalue': { S: 'TST-0000aaaa' }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data.items,
        type: SET_ARTICLES,
        lastEvalKey: data.lastEvalKey
      }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: 'a'
      }
    }
  })

export const putItem = (values, formType) => {
  const SK =
    values.SK ??
    (formType === "contactForm" // 7.4.2 from Figma
      ? "CONTACT_RESPONSE"
      : formType === "signUpForm"
      ? "SIGNUP_RESPONSE"
      : formType === "careersForm"
      ? "CAREER_RESPONSE"
      : formType === "jobForm" // 7.2.2 from Figma
      ? "JOB_RESPONSE"
      : formType === "guestArticle" // 7.1.2 from Figma
      ? "GUEST_ARTICLE_V2"
      : formType === "guestJob"
      ? "GUEST_JOB"
      : formType === "guestAddList"
      ? "GUEST_ADD_LIST"
      : formType === "locationForm" // 7.5.2 from  Figma
      ? "LOCATION_RESPONSE"
      : formType === "aplicantForm" // 7.3.2 from Figma
      ? "APLICANT_RESPONSE"
      : undefined)

    const eDate = moment().toISOString()
    const cDate = values.cDate ?? eDate

    return dynamoAction({
    method: 'PUT',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      Item: AWS.DynamoDB.Converter.marshall({
        PK: values.PK ?? geenrateId(formType),
        SK: values.SK ?? SK,
        values,
        cDate, //creation date
        eDate, //last edit date
      })
    },
    onSuccess: (data,dispatch) => {
      showMagicToaster({ title: 'Datele au fost salvate' })
      //if in edit mode, cancel it
      // const url = window.location.pathname.split("/")
      // if (url[url.length-1] === "edit") cancelEdit()
      dispatch(setPageEdit(false));
      return { type: 'a' }
    },
    onFailure: (err) => {
      showMagicToaster({
        title: 'A aparut o eroare la salvarea datelor',
        subtitle: 'E201',
        type: 'error'
      })
      return {
        type: 'a'
      }
    }
  })
}

export const putSelectData = (data, selectDataName) => {
  const SK = 'SELECT_OPTIONS'
  return dynamoAction({
    method: 'PUT',
    parameters: {
      TableName: 'TempDevStack-MainTable-74SE74RNUI3D',
      Item: AWS.DynamoDB.Converter.marshall({
        PK: geenrateId('SelectOptions'),
        SK,
        lastUpdate: moment().toISOString(),
        name: selectDataName,
        data
      })
    },
    onSuccess: (data) => {
      showMagicToaster({ title: 'Datele au fost salvate' })
      return { type: 'a' }
    },
    onFailure: (err) => {
      showMagicToaster({
        title: 'A aparut o eroare la salvarea datelor',
        subtitle: 'E201',
        type: 'error'
      })
      return {
        type: 'a'
      }
    }
  })
}

// export const sendFormDetails = (values, formType) =>
//   dynamoAction({
//     method: params.method,
//     parameters: params.params,
//     onSuccess: (data, dispatch) => {
//       console.log("got item", data.Item)
//       if (data.Item === undefined) {
//         let tmp = { id: response.id, data: response.response }
//         let newSurvey = {
//           PK: userId,
//           SK: surveyId,
//           inProgress: inProgress,
//           userId: userId,
//           startDate: moment().local().format(),
//           lastAction: moment().local().format(),
//           responses: [tmp],
//           token: uuidv4(),
//           tokenExpiration: moment().add(7, 'd').local().format(),
//           email: email,
//           name: name,
//           userAgent : navigator.userAgent,
//         }
//         let queryParamsPut = {
//           method: "put",
//           params: {
//             TableName: "TempDevStack-MainTable-74SE74RNUI3D",
//             Item: AWS.DynamoDB.Converter.marshall(newSurvey)
//           }
//         }
//         dispatch(putItem(queryParamsPut));
//       }
//       return {
//         type: "a",
//       };
//     },
//     onFailure: err => {
//       console.log(err);
//       return {
//         type: "a"
//       };
//     }
//   });
