/*
* input: { permissions: ["citizen read", "citizen write", "citizen delete"] }
* output: { permissions: {citizen: ["read", "write", "delete"]} }
* */

export default function parsePermissions({ permissions: rawPerms }) {
    if (!rawPerms) return
    const permissions = {}

    for (const perm of rawPerms) {
        const [key, value] = perm.split(" ")
        if (!permissions[key]) permissions[key] = []
        permissions[key].push(value)
    }

    return { permissions }
}

export const PERMISSIONS = {
    read: "read",
    write: "write",
    delete: "delete"
}
export const PERMISSIONS_LEVELS = {
    read: 1,
    write: 2,
    delete: 3
}

export const getPermissionByLevel = (level) => Object.entries(PERMISSIONS_LEVELS).find(([_, _level]) => _level === level)[0]


/*
* input: [ { id: 1, name: "citizen read" }, { id: 2, name: "citizen write" } ]
* output: { citizen: { read: 1, write: 2 } }
* */

export function advancedPermissionsParser(permissionsArray) {
    if (!permissionsArray) return {}
    const permissions = {}

    for (const perm of permissionsArray) {
        const [key, value] = perm.name.split(" ")
        if (!permissions[key]) permissions[key] = {}
        permissions[key][value] = perm.id
    }

    return permissions
}