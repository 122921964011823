import PropTypes from "prop-types"
import { useSelector } from "store"

export function Loc(localizations) {
    const lang = useSelector(state => state?.lang?.lang)

    return localizations[lang] ?? null
}

export const loc = (ro, hu) => Loc({RO: ro, HU: hu})

Loc.propTypes = {
    RO: PropTypes.string,
    HU: PropTypes.string
}