import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'RO',
  lng: 'RO',
  resources: {
    RO: {
      translations: require('./locales/ro.json')
    },
    HU: {
      translations: require('./locales/hu.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['RO', 'HU'];

export default i18n;