import { createBrowserHistory } from 'history';

// export const history = createBrowserHistory();
export const history = createBrowserHistory({forceRefresh: true});

const createUrlPrefix = ({customUrl, customTags}) => `${customUrl}`
.concat(customTags.length ? "-":"")
.concat(customTags.join("-"));

// export const goToProductDetails = (id) => history.push(`/shop/details?id=${id}`)
export const goToProductDetails = (id, customTags =  ["mocktag1","mocktag2"], customUrl="customUrl") => history.push(`/shop/details/${createUrlPrefix({customTags, customUrl})}?id=${id}`)