import { createSelector } from "reselect";

const language = (state) => state.user?.language;

export const allYupMessages = {
  ES: {
    mixed: {
      required: "Estos campos es obligatorio!",
    },
    string: {
      min: "¡Ingrese un mínimo de ${min} caracteres!",
      max: "¡Ingrese un máximo de ${max} caracteres!",
      url: "El enlace proporcionado es incorrecto (Ejemplo: https://...)!",
      email: "¡La dirección de correo electrónico que ha introducido es incorrecta!",
    },
  },
  EN: {
    mixed: {
      required: "Required field!",
    },
    string: {
      min: "Enter a minimum of ${min} characters!",
      max: "Enter a maximum of ${max} characters!",
      url: "The link provided is incorrect (EX: https://...)!",
      email: "The email address you entered is incorrect!",
    },
  },
  HU: {
    mixed: {
      required: "Kötelező mező!",
    },
    string: {
      min: "Írjon be minimum ${min} karaktert!",
      max: "Írjon be maximum ${max} karaktert!",
      url: "A megadott link helytelen (PÉLDA: https://...)!",
      email: "A megadott email cím helytelen!",
    },
  },
  RO: {
    mixed: {
      required: "Câmp obligatoriu!",
    },
    string: {
      min: "Introduceți minim ${min} caractere!",
      max: "introduceți maxim ${max} caractere!",
      url: "Linkul introdus nu este valid (EX: https://...)!",
      email: "Adresa de email este invalidă!",
    },
  },
};

export const selectFormErros = createSelector(language, (language) => {
  return {
    yupLocale: allYupMessages[language],
  };
});
