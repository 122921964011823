import './MainLayout.scss'
import TopMenu from "./TopMenu"
import LeftMenu from "./LeftMenu"
import { useEffect, useState } from "react"
import useSize from "utils/useSize"
import jwt from "jsonwebtoken"
import { useSelector } from "store"
import { history } from "utils/history"

export const inactiveState = {sideMenuActive: false, blur: false, profileDropdownActive: false}

export default function MainLayout({ children }) {
    const [localState, setLocalState] = useState({})
    const size = useSize()
    const isPhone = size === "sm" || size === 'md'

    useEffect(() => {
        if (isPhone && localState?.sideMenuActive && localState?.profileDropdownActive) {
            setLocalState(state => ({...state, profileDropdownActive: false}))
        }
    }, [isPhone])

    const token = useSelector(state => state?.auth?.token)

    useEffect(() => {
        const tokenDecoded = jwt.decode(token)
        const isExpired = Date.now() >= tokenDecoded?.exp * 1000
        if (!tokenDecoded || isExpired) {
            history.push('/portal/auth/signin')
        }
    }, [token])

    return (
        <div className="app flex flex-col h-screen">
            <TopMenu state={localState} setState={setLocalState}/>
            <div className={`flex grow ${isPhone && localState?.sideMenuActive && 'overflow-hidden'}`}>
                <LeftMenu state={localState} setState={setLocalState}/>
                <main
                    onClick={() => {setLocalState(x => ({...x, ...inactiveState}))}}
                    className={`layout-main p-6 grow ${localState.blur && 'blur-bg'}`}>
                    {children}
                </main>
            </div>
        </div>
    )
}