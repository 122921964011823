import * as yup from "yup"
import { t } from "i18next"


function isValidCNP({
    isRequired = true,
    cnpError = 't__admin.representativeForm.cnpError'
} = {}) {



    return this.test('cnp_validation', (value, { createError, path }) => {
        if ((!isRequired && !value) || validateCNP(value)) return true
        return createError({
            path,
            message: value ? cnpError : true,// used to emulate error
        })
    })
}

yup.addMethod(yup.string, "isValidCNP", isValidCNP)


function validateCNP (value) {
    let re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
        bigSum = 0,
        rest = 0,
        ctrlDigit = 0,
        control = '279146358279',
        i = 0;
    if (re.test(value)) {
        for (i = 0; i < 12; i++) {
            bigSum += value[i] * control[i]
        }
        ctrlDigit = bigSum % 11
        if (ctrlDigit === 10) {
            ctrlDigit = 1
        }

        return ctrlDigit === parseInt(value[12], 10)
    }
    return false
}