export function makeid(length) {
  var result = ''
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export default function genrateId(type) {
  if (type === 'contactForm') return `CFR-${makeid(8)}`
  if (type === 'signUpForm') return `SFR-${makeid(8)}`
  if (type === 'careersForm') return `CRF-${makeid(8)}`
  if (type === 'SelectOptions') return `DDI-${makeid(8)}`
  if (type === 'guestArticle') return `GAA-${makeid(8)}`
  if (type === 'guestAddList') return `GAL-${makeid(8)}`
  if (type === 'jobForm') return `JOB-${makeid(8)}`
  if (type === "locationForm") return `LOC-${makeid(8)}`
  if (type === "aplicantForm") return `APL-${makeid(8)}`
}

export function getSKfromType(type) {
  if (type === "CFR") return  "CONTACT_RESPONSE"  // 7.4.2 from Figma
  if (type === "SFR") return  "SIGNUP_RESPONSE" 
  if (type === "CRF") return  "CAREER_RESPONSE" 
  if (type === "GAA") return  "GUEST_ARTICLE_V2"  // 7.1.2 from Figma
  if (type === "GAL") return  "GUEST_ADD_LIST" 
  if (type === "JOB") return  "JOB_RESPONSE"  // 7.2.2 from Figma
  if (type === "guestJob") return  "GUEST_JOB" 
  if (type === "LOC") return  "LOCATION_RESPONSE"  // 7.5.2 from  Figma
  if (type === "APL") return  "APLICANT_RESPONSE"  // 7.3.2 from Figma
  }
