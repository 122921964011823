import axios from "axios";
import { history } from "utils/history";
import AWS from "aws-sdk"
import { SET_AUTHENTICATED } from "store/modules/auth";
///////
// axios.defaults.withCredentials = true;
import jwt from 'jsonwebtoken';

export const API = "api/API";
const API_START = "api/API_START";
const API_END = "api/API_END";
const API_ERROR = "api/API_ERROR";
const ACCESS_DENIED = "api/ACCESS_DENIED";
const ON_FAILURE = "api/ON_FAILURE";
export const DYNAMO = 'sdk/DYNAMO'
export const NEW_DISPATCH = 'redux/DISPATCH'

export const SQL = 'sdk/SQL'

let url = process.env.REACT_APP_API_ENDPOINT;

export const apiStart = label => ({
  type: API_START,
  payload: label
});

export const apiEnd = label => ({
  type: API_END,
  payload: label
});

export const apiError = error => ({
  type: API_ERROR,
  error
});

export const accessDenied = error => ({
  type: ACCESS_DENIED,
  error
});

export const onFailure = error => ({
  type: ON_FAILURE,
  error
});

// export const logOutUser = () => ({
//   type: LOGOUT_USER
// });

export const apiAction = ({
  endPoint = "",
  method = "GET",
  data = null,
  // headers = null,
  onSuccess = () => { },
  onFailure = () => { },
  extraHeaders = null,
  label = "",
  baseUrl = null,
  qs = false,
}) => ({
  type: API,
  payload: {
    endPoint,
    method,
    data,
    onSuccess,
    onFailure,
    extraHeaders,
    label,
    baseUrl,
    qs,
  }
});

export const dynamoAction = ({
  method = "QUERY",
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: DYNAMO,
  payload: {
    method,
    parameters,
    onSuccess,
    onFailure,
  }
});

export const sqlAction = ({
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: SQL,
  payload: {
    parameters,
    onSuccess,
    onFailure,
  }
});

export const reDispatch = (innerFunction) => ({
  type: NEW_DISPATCH,
  payload: innerFunction
});

const apiMiddleware = ({ getState, dispatch }) => next => action => {
  //console.log("API ACTION", action);

  // next(action);
  if (action.type !== API && action.type !== DYNAMO && action.type != SQL && action.type!== NEW_DISPATCH) {
    return next(action);
  }
  if (action.type === NEW_DISPATCH) {
    const {next, onSuccess} = action.payload

    //console.log("test")
    dispatch(action.payload( dispatch))
  }

  if (action.type == API) {
    const {
      endPoint,
      method,
      data,
      accessToken,
      extraHeaders,
      onSuccess,
      onFailure: onFailureCustom,
      label,
      baseUrl,
      qs,
    } = action.payload;
    // GET & DELETE  use params vs POST which may require data
    const dataOrParams = (["GET", "DELETE"].includes(method) || qs) ? "params" : "data";

    let updatedHeaders = {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Credentials':true
    }
    axios.defaults.headers.common["Content-Type"] = "application/json";
    // headers && headers.map(header => axios.defaults.headers.common[header.name] = header.value)
    // add token to req
    // const isToken = localStorage.getItem("user");
    // console.log({extraHeaders})
    // extraHeaders && extraHeaders.forEach(header =>
    //   axios.defaults.headers.common[header.name] = header.value
    //   )
    // let token = null;
    const state = getState();
    const { auth } = state;
    const token = auth?.token;
    const lang = state?.lang?.lang?.toLowerCase()

    if (lang) updatedHeaders['Accept-Language'] = lang

    //console.log("isToken", isToken);
    // if (isToken) {
    //   token = JSON.parse(isToken).IdToken;
    // }
    // console.log(token);

    if (token) updatedHeaders["Authorization"] = 'Bearer ' + token;
    // if (token) axios.defaults.headers.common["Authorization"] = token;

    if (label) {
      dispatch(apiStart(label));
    }
    //   Progress.show();
    // dispatch(showLoading());
    //   dispatch(show())

    url = baseUrl ? `${baseUrl}${endPoint}` : `${process.env.REACT_APP_API_ENDPOINT}${endPoint}`;

    const onRefreshError = () => {
      history.push("/login");
    }
    const onRefreshSuccess = () => axios
      .request({
        url,
        method,
        // mode: 'no-cors',
        headers: updatedHeaders,
        [dataOrParams]: data,
        // withCredentials: true
      })
      .then(({ data, headers }) => {
        // console.log(headers)
        // dispatch(hideLoading());
        dispatch(onSuccess(data, dispatch)) })
      .catch(error => {
        // dispatch(resetLoading())
        dispatch(onFailure(error, dispatch));
        if (
          error.response &&
          error.response.data &&
          typeof error.response.data === "string"
        ) {
        }

        if (error.response) {
          if (error.response.status === 401) {
          // if (error.response.status === 401 || error.response.status === 403) {
            // dispatch(logoutUser());
            dispatch({type:SET_AUTHENTICATED, payload:false})
            history.push("/login");
          }
        }
        dispatch(apiError(error));
        if (onFailure) dispatch(onFailure(error));
        if (onFailureCustom) {
          //onFailureCustom(error);
          dispatch(onFailureCustom(error));
        }

        if (error.response && error.response.status === 404) {
          dispatch(accessDenied(window.location.pathname));
        }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
    refreshTokenCheck({ getState, dispatch, onRefreshError,onRefreshSuccess })

  }
  else if (action.type == DYNAMO) {
    const {
      method,
      parameters,
      onSuccess,
      onFailure
    } = action.payload;

    var dynamodb = new AWS.DynamoDB();
    if (method == "QUERY") {
      // dispatch(showLoading());
      console.log("getState", getState());
      return dynamodb.query(parameters).promise()
        .then(data => {
          var unmarshalled = data.Items.map(item => item = AWS.DynamoDB.Converter.unmarshall(item));
          var lastEvalKey = data.LastEvaluatedKey
          var returnData = { items: unmarshalled, lastEvalKey }
          // dispatch(hideLoading());
          dispatch(onSuccess(returnData, dispatch));
        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
          }

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          if (onFailure) dispatch(onFailure(error));
        });
    }
    else if (method == "PUT") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.putItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
          }

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }
    else if (method === "DELETE") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.deleteItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
          }

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }
    else if (method == "batch_write") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.batchWriteItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {

          }

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }

  }
  else if (action.type === SQL) {
    console.log("action")
    const {
      parameters,
      onSuccess,
      onFailure
    } = action.payload;

    var rds = new AWS.RDSDataService();
    var params = {
      resourceArn: 'arn:aws:rds:eu-central-1:582846778384:cluster:database-1', /* required */
      secretArn: 'arn:aws:secretsmanager:eu-central-1:582846778384:secret:database-1-admin-1Z10ci', /* required */
      sql: parameters, /* required */ // sql statement
      database: 'database-1'
    }
    // dispatch(showLoading());

    return rds.executeStatement(params).promise()
    .then(data => {
      // dispatch(hideLoading());
      dispatch(onSuccess(data, dispatch));

    })
    .catch(error => {
      console.log("PRINT ERR", error, error.stack);
      // dispatch(resetLoading());
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "string"
      ) {

      }

      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          // dispatch(logoutUser());
          history.push("/login");
        }
      }
      dispatch(apiError(error));
      dispatch(onFailure(error));
    });

  }
};

export default apiMiddleware;


const refreshTokenCheck = ({ getState, dispatch, onRefreshSuccess, onRefreshError}) => {
//   //console.log('...checking token...');
//   const token = getState()?.auth?.token
//   const refreshToken = getState()?.auth?.token
//   const decoded = jwt.decode(token,  {
//       complete: true
//      })
//   const now = new Date();
//   const tokenExp = new Date(decoded?.payload?.exp * 1000)
//   // debugger
//   if (tokenExp > now) {
//     //console.log('exp token')
//     let refreshTokenUrl = process.env.REACT_APP_API_ENDPOINT + `/api/refresh-token`;
//     const body = { refreshToken }
//     //axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/logout', {}, {Authorization: 'Bearer ' + refreshToken}).then(resp => {console.log('post', resp)}).catch(error => {console.log(error)})
//     axios.request({url: refreshTokenUrl, method: 'POST'}).then(res => {
//         console.log(res);
//         dispatch({ type: REFRESH_TOKENS, payload: res.data })
//         return onRefreshSuccess();
//       }).catch( error => {
//         console.log(error);
//         // debugger
//         return onRefreshSuccess();
//         // dispatch({type:SET_AUTHENTICATED, payload:false}) //temp usage before rele implementation
//         // dispatch({type:RESET_USER_INFO}) 
//         // history.push("/login");
//     });
// }
return onRefreshSuccess();

}

axios.interceptors.response.use(async response => {
    if (response.data?.data === 'Token expired') {
        history.push('/portal/auth/signin')
    }
    return response
})