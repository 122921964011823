import logoRO from "assets/navbar/sepsi-ro.svg"
import logoHU from "assets/navbar/sepsi-hu.svg"
import { useSelector } from "react-redux"

export default function Logo() {
    const lang = useSelector(state => state?.lang?.lang)

    return (
        <div className="flex">
            <img height='80' src={{RO: logoRO, HU: logoHU}[lang]} alt="" />
        </div>
    )
}